import { useCallback, useEffect, useRef } from "react";
import * as React from "react";
import { cep, cpf, currency, phone } from "./masks";
import { StyledInput, InputDiv } from "./styles";

interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
	name: string;
	children: string;
	disabled?: boolean;
	defaultValue?: string;
	id?: string;
	mask?: string;
	placeholder?: string;
	type?: string;
	value?: string | number | readonly string[];
	required?: boolean;
	onChange?: ((e: React.ChangeEvent<HTMLInputElement>) => void);
}

const Input = (props: InputProps) => {
	const input = useRef<HTMLInputElement>(null);

	const handleMask = useCallback(
		(e: React.FormEvent<HTMLInputElement>) => {
			switch (props.mask) {
			case "cep":
				cep(e);
				break;
			case "cpf":
				cpf(e);
				break;
			case "currency":
				currency(e);
				break;
			case "phone":
				phone(e);
				break;
			default:
				break;
			}
		},
		[props.mask]
	);

	const hasInput = () => {
		if (input.current) {
			if (input.current.value === "" || props.value === "") {
				input.current.classList.add("no-input");
			}
			else {
				input.current.classList.remove("no-input");
			}
		}
	};

	useEffect(() => {
		hasInput();
	}, [input.current?.value, props.value]);

	return <InputDiv width={props.type === "date" || props.type === "datetime-local" ? "251px" : "auto"}>
		<StyledInput
			type={props.type ? props.type : "text"} defaultValue={props.defaultValue} 
			name={props.name} 
			value={props.value}
			ref={input} 
			id={props.id ? props.id : props.name} 
			onInput={hasInput} 
			onChange={(e) => {
				if (props.onChange) {
					props.onChange(e);
				}
			}} 
			disabled={props.disabled} 
			required={props.required} 
			placeholder={props.placeholder} 
			className={`${props.disabled ? "disabled" : ""}`} 
			onKeyUp={e => {
				if (props.mask) {
					handleMask(e);
				}

			}} />
		<label htmlFor={props.id ? props.id : props.name}>{props.children}</label>
	</InputDiv>;
};

export default Input;