export function OpenModal(modalId: string) {
	document.getElementsByTagName("body")[0].style.overflow = "hidden";
	const modal = document.getElementById(modalId);
	if(modal) {
		modal.style.display = "flex";
	}
	return;
}

export function CloseModal(modalId: string) {
	document.getElementsByTagName("body")[0].style.overflow = "hidden";
	const modal = document.getElementById(modalId);
	if(modal) {
		modal.style.display = "none";
	}
	return;
}