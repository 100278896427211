import { faEdit } from "@fortawesome/free-regular-svg-icons";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Pagination, PaginationItem, PaginationLink } from "reactstrap";
import returnDateAndHour from "../../functions/returnDateAndHour";
import Modal from "../../components/Modal/Modal";
import colors from "../../constants/colors";
import { Links } from "../../types/Links";
import { PaginationData } from "../../types/PaginationData";
import { ReportedGamesType } from "../../types/ReportedGamesType";
import { ReportedGameType } from "../../types/ReportedGameType";
import { BlackScreen, ContainerNav, CustomButton, CustomTable, Divisor, Jogador, ModalFormContainer, Partida, Tab, TabContent } from "./styles";
import WhoReported from "./Functions/WhoReported";
import { changeData, closeBlackScreen, editData, getChallengeData, getData, handleChangeStatus, handleChangeVotes, openBlackScreen, openTab, statusDropdownToggle, translateStatus, votePlayerADropdownToggle, votePlayerBDropdownToggle } from "./Functions/Actions";
import Button from "../../components/Buttons/Button";
import OutlinedButton from "../../components/Buttons/OutlinedButton";
import { OpenModal } from "../../functions/modalFunctions";
import EndReportModal from "./Modals/EndReportModal/EndReportModal";
import axiosInstance from "../../services/axiosInstance";
import RefundModal from "./Modals/RefundModal/RefundModal";

function ReportedGames() {
	const [challengesArray, setChallenges] = useState<ReportedGamesType[] | null>(null);
	const [paginationData, setPaginationData] = useState<PaginationData | null>(null);
	const [links, setLinks] = useState<Links[] | null>(null);
	const [selectedChallenge, setSelectedChallenge] = useState<ReportedGameType | null>(null);
	const [statusDropdown, setStatusDropdown] = useState<boolean>(false);
	const [votePlayerADropdown, setVotePlayerADropdown] = useState<boolean>(false);
	const [votePlayerBDropdown, setVotePlayerBDropdown] = useState<boolean>(false);
	const [status, setStatus] = useState<string>("");
	const [votePlayerA, setVotePlayerA] = useState<"Ganhou" | "Perdeu" | "">("");
	const [votePlayerB, setVotePlayerB] = useState<"Ganhou" | "Perdeu" | "">("");

	useEffect(() => {
		getData(setPaginationData, setChallenges, setLinks);
	}, []);

	useEffect(() => {
		if (selectedChallenge) {
			setStatus(translateStatus(selectedChallenge.match_status));
			setVotePlayerA(selectedChallenge.result_playerA === 1 ? "Ganhou" : "Perdeu");
			setVotePlayerB(selectedChallenge.result_playerB === 1 ? "Ganhou" : "Perdeu");
		}
	}, [selectedChallenge]);

	return <>
		<Modal style={{ zIndex: 5 }} notFirst id="reportModalWarning">
			<RefundModal  setPaginationData={setPaginationData} setChallenges={setChallenges} setLinks={setLinks} setSelectedChallenge={setSelectedChallenge} match={selectedChallenge ? selectedChallenge : null} getChallengeData={getChallengeData}/>
		</Modal>
		<Modal style={{ zIndex: 5 }} notFirst id="endReportModalWarning">
			<EndReportModal match={selectedChallenge ? selectedChallenge : null} setPaginationData={setPaginationData} setChallenges={setChallenges} setLinks={setLinks}/>
		</Modal>
		<Modal id="challengeData">
			{selectedChallenge ? <ModalFormContainer>
				<Tab>
					<button type="button" className="tablinks active" onClick={(e) => { openTab(e, "Jogadores"); }}>Jogadores</button>
					<button type="button" className="tablinks" onClick={(e) => { openTab(e, "Partida"); }}>Partida</button>
					<button type="button" className="tablinks" onClick={(e) => { openTab(e, "Imagens"); }}>Imagens</button>
					<button type="button" className="tablinks" onClick={(e) => { openTab(e, "Chat"); }}>Chat</button>
					<button type="button" className="tablinks" onClick={(e) => { openTab(e, "Dados"); }}>Dados</button>
				</Tab>
				<TabContent id="Jogadores" style={{ display: "block" }} className="tabcontent active">
					<Jogador>
						<span>{selectedChallenge.match_playerA?.nickname} {WhoReported(selectedChallenge, selectedChallenge.match_playerA?.id)}</span>
						<span>ID: <span style={{ color: colors.WHITE }}>{selectedChallenge.match_playerA?.id}</span></span>
						<span>Email: <span style={{ color: colors.WHITE }}>{selectedChallenge.match_playerA?.email}</span></span>
						<span>Status: <span style={{ color: colors.WHITE }}>{selectedChallenge.match_playerA?.status}</span></span>
					</Jogador>
					<Divisor />
					<Jogador>
						<span>{selectedChallenge.match_playerB?.nickname} {WhoReported(selectedChallenge, selectedChallenge.match_playerB?.id)}</span>
						<span>ID: <span style={{ color: colors.WHITE }}>{selectedChallenge.match_playerB?.id}</span></span>
						<span>Email: <span style={{ color: colors.WHITE }}>{selectedChallenge.match_playerB?.email}</span></span>
						<span>Status: <span style={{ color: colors.WHITE }}>{selectedChallenge.match_playerB?.status}</span></span>
					</Jogador>
				</TabContent>
				<TabContent id="Partida" className="tabcontent">
					<Partida>
						<span>ID: <span style={{ color: colors.WHITE }}>{selectedChallenge.match_id}</span></span>
						<span>Tipo de jogo: <span style={{ color: colors.WHITE }}>{selectedChallenge.match_game.toUpperCase()}</span></span>
						<span>Entrada: <span style={{ color: colors.WHITE }}>{selectedChallenge.ticket}</span></span>
						<span>Prêmio: <span style={{ color: colors.WHITE }}>{selectedChallenge.prize}</span></span>
						<span>Voto do {selectedChallenge.match_playerA?.nickname}: <span style={{ color: colors.WHITE }}>{selectedChallenge.result_playerA ? selectedChallenge.result_playerA : "Nenhum"}</span></span>
						<span>Voto do {selectedChallenge.match_playerB?.nickname}: <span style={{ color: colors.WHITE }}>{selectedChallenge.result_playerB ? selectedChallenge.result_playerB : "Nenhum"}</span></span>
						<span>Descrição: <span style={{ color: colors.WHITE }}>{selectedChallenge.report_description}</span></span>
						<span>Estado do Reporte: <span style={{ color: colors.WHITE }}>{selectedChallenge.report_status}</span></span>
						<span>Desafio criado em: <span style={{ color: colors.WHITE }}>{returnDateAndHour(selectedChallenge.match_updated_at)}</span></span>
						<span>Reportado em: <span style={{ color: colors.WHITE }}>{returnDateAndHour(selectedChallenge.report_updated_at)}</span></span>
					</Partida>
				</TabContent>
				<TabContent id="Imagens" className="tabcontent">
					<img onClick={() => { openBlackScreen(selectedChallenge.internal_media_url); }} src={selectedChallenge.internal_media_url} width="100%" height="100%" style={{ marginBottom: 5 }} />
					<span style={{ fontSize: 18 }}>Link externo: <a target="_blank" rel="noreferrer" href={selectedChallenge.external_video_url} style={{ color: colors.WHITE }}>{selectedChallenge.external_video_url}</a></span>
				</TabContent>
				<TabContent id="Chat" className="tabcontent">
					<div style={{ display: "flex", justifyContent: "center" }}>
						<span style={{ textAlign: "center", fontSize: 22, fontWeight: "bold" }}>EM BREVE</span>
					</div>
				</TabContent>
				<TabContent id="Dados" className="tabcontent">
					<div style={{ display: "flex", gap: 10, flexDirection: "column" }}>
						<form onSubmit={e => handleChangeVotes(e, selectedChallenge, votePlayerA, votePlayerB, setSelectedChallenge)}>
							<div style={{ display: "flex", gap: 10, alignItems: "center", padding: 5 }}>
								<span style={{ maxWidth: "280px", whiteSpace: "nowrap", maxHeight: 20, display: "inline-block", textOverflow: "ellipsis", overflow: "hidden", flex: 1 }}>Alterar voto do {selectedChallenge.match_playerA?.nickname}:</span>
								<Dropdown id="addVotePlayerA" style={{ width: "100%", maxWidth: "251px", height: "100%" }} isOpen={votePlayerADropdown} toggle={() => votePlayerADropdownToggle(setVotePlayerADropdown)} direction="down">
									<DropdownToggle style={{ width: "100%", border: `1px solid ${colors.PRIMARY_GREEN}`, height: "100%", backgroundColor: colors.PRIMARY_BLACK, color: colors.PRIMARY_GREEN }} size="md" caret>{votePlayerA}</DropdownToggle>
									<DropdownMenu container="body" style={{ width: 251 }} dark>
										<DropdownItem onClick={() => { setVotePlayerA("Ganhou"); }}>Ganhou</DropdownItem>
										<DropdownItem onClick={() => { setVotePlayerA("Perdeu"); }}>Perdeu</DropdownItem>
									</DropdownMenu>
								</Dropdown>
							</div>
							<div style={{ display: "flex", gap: 10, alignItems: "center", padding: 5 }}>
								<span style={{ maxWidth: "280px", whiteSpace: "nowrap", maxHeight: 20, display: "inline-block", textOverflow: "ellipsis", overflow: "hidden", flex: 1 }}>Alterar voto do {selectedChallenge.match_playerB?.nickname}:</span>
								<Dropdown id="addVotePlayerB" style={{ width: "100%", maxWidth: "251px", height: "100%" }} isOpen={votePlayerBDropdown} toggle={() => votePlayerBDropdownToggle(setVotePlayerBDropdown)} direction="down">
									<DropdownToggle style={{ width: "100%", border: `1px solid ${colors.PRIMARY_GREEN}`, height: "100%", backgroundColor: colors.PRIMARY_BLACK, color: colors.PRIMARY_GREEN }} size="md" caret>{votePlayerB}</DropdownToggle>
									<DropdownMenu container="body" style={{ width: 251 }} dark>
										<DropdownItem onClick={() => { setVotePlayerB("Ganhou"); }}>Ganhou</DropdownItem>
										<DropdownItem onClick={() => { setVotePlayerB("Perdeu"); }}>Perdeu</DropdownItem>
									</DropdownMenu>
								</Dropdown>
							</div>
							<div style={{ display: "flex", width: "100%", justifyContent: "end" }}>
								<Button type="submit" width="100px">Alterar</Button>
							</div>
						</form>
						<form onSubmit={e => handleChangeStatus(e, selectedChallenge, status, setSelectedChallenge, setPaginationData, setChallenges, setLinks)} >
							<div>
								<div style={{ display: "flex", gap: 10, alignItems: "center", padding: 5 }}>
									<span style={{ flex: 1 }}>Alterar status do desafio:</span>
									<Dropdown id="addStatus" style={{ width: "100%", maxWidth: "251px", height: "100%" }} isOpen={statusDropdown} toggle={() => statusDropdownToggle(setStatusDropdown)} direction="down">
										<DropdownToggle style={{ width: "100%", border: `1px solid ${colors.PRIMARY_GREEN}`, height: "100%", backgroundColor: colors.PRIMARY_BLACK, color: colors.PRIMARY_GREEN }} size="md" caret>{status}</DropdownToggle>
										<DropdownMenu container="body" style={{ width: 251 }} dark>
											<DropdownItem onClick={() => { setStatus("Votando"); }}>Votando</DropdownItem>
											<DropdownItem onClick={() => { setStatus("Reportado"); }}>Reportado</DropdownItem>
											<DropdownItem onClick={() => { setStatus("Finalizado"); }}>Finalizado</DropdownItem>
										</DropdownMenu>
									</Dropdown>

								</div>
							</div>
							<div style={{ display: "flex", width: "100%", justifyContent: "end" }}>
								<Button type="submit" width="100px">Trocar</Button>
							</div>
						</form>
					</div>
				</TabContent>
				<div id="oparationButtons" style={{ display: "none", width: "100%", justifyContent: "flex-end", gap: 10 }}>
					<OutlinedButton type="button" title="Encerra a partida e reembolsa" width="130px" danger onClick={() => { OpenModal("reportModalWarning"); }}>Reembolsar</OutlinedButton>
					{selectedChallenge.report_status === "pending" ?
						<OutlinedButton type="button" title="Encerra o reporte" width="130px" danger onClick={() => { OpenModal("endReportModalWarning"); }}>Encerrar</OutlinedButton> :
						<Button type="button" title="Abre o reporte novamente" width="130px" onClick={() => {
							axiosInstance.post(`/api/admin/support/report/${selectedChallenge.report_id}/reopen`).then(res => {
								if (res.data.success) {
									alert("Reporte aberto com sucesso!");
								}
							});
						}}>Abrir</Button>
					}
				</div>
			</ModalFormContainer> :
				<div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}><FontAwesomeIcon icon={faSpinner} className="fa-spin" size="3x" color={colors.PRIMARY_GREEN} /></div>}
		</Modal>
		<ContainerNav>
			<BlackScreen id="blackScreen" onClick={() => { closeBlackScreen(); }}>
				<img id="blackScreenImage" style={{ display: "none" }} width="auto" height="auto" />
			</BlackScreen>
			<div style={{ display: "flex", gap: 10, justifyContent: "center", alignItems: "center", height: 80 }}>
				<span style={{ fontSize: 40, fontWeight: "bold" }}>Reportes</span>
			</div>
			<div style={{ width: "100%", height: "100%", overflow: "scroll", display: "flex", justifyContent: "start", alignItems: "center", flexDirection: "column" }}>
				{challengesArray ? <CustomTable>
					<thead>
						<tr>
							<th>
								Ticket
							</th>
							<th>
								Partida
							</th>
							<th>
								Jogo
							</th>
							<th>
								Situação
							</th>
							<th>
								Criado
							</th>
							<th>
								Atualizado
							</th>
							<th />
						</tr>
					</thead>
					<tbody>
						{challengesArray && challengesArray.map((challenge) => {
							return <tr key={challenge.match_id}>
								<td scope="row">
									#{challenge.report_id}
								</td>
								<td>
									{challenge.game.toLocaleUpperCase()}
								</td>
								<td>
									{challenge.match_id}
								</td>
								<td>
									{challenge.report_status}
								</td>
								<td>
									{challenge.report_created_at}
								</td>
								<td>
									{challenge.report_updated_at}
								</td>
								<td style={{ width: 0, padding: 10 }}>
									<CustomButton onClick={() => editData(challenge.match_id, setSelectedChallenge)} style={{ backgroundColor: colors.PRIMARY_GREEN, border: 0, color: colors.PRIMARY_BLACK }}><FontAwesomeIcon icon={faEdit} size="lg" /></CustomButton>
								</td>
							</tr>;
						})}
					</tbody>
				</CustomTable> : <FontAwesomeIcon icon={faSpinner} className="fa-spin" size="3x" color={colors.PRIMARY_GREEN} />}

				<div style={{ marginTop: 20, display: "flex", justifyContent: "center" }}>
					<Pagination>
						<PaginationItem>
							<PaginationLink
								first
								onClick={() => { paginationData ? changeData(setChallenges, setPaginationData, setLinks, paginationData.first_page_url) : null; }}
							/>
						</PaginationItem>
						<PaginationItem>
							<PaginationLink
								previous
								onClick={() => { paginationData && paginationData.prev_page_url ? changeData(setChallenges, setPaginationData, setLinks, paginationData.prev_page_url) : null; }}
							/>
						</PaginationItem>
						<>{links?.length ? links.map(link => {
							if (link.label.includes("Previous") || link.label.includes("Next")) { return; }
							return <PaginationItem key={link.url} onClick={() => changeData(setChallenges, setPaginationData, setLinks, link.url)} className={link.active ? "active" : ""}>
								<PaginationLink>
									{link.label}
								</PaginationLink>
							</PaginationItem>;
						}) : <></>}</>
						<PaginationItem>
							<PaginationLink
								next
								onClick={() => { paginationData && paginationData.next_page_url ? changeData(setChallenges, setPaginationData, setLinks, paginationData.next_page_url) : null; }}
							/>
						</PaginationItem>
						<PaginationItem>
							<PaginationLink
								last
								onClick={() => { paginationData ? changeData(setChallenges, setPaginationData, setLinks, paginationData.last_page_url) : null; }}
							/>
						</PaginationItem>
					</Pagination>
				</div>
			</div>
		</ContainerNav>
	</>;
}

export default ReportedGames;