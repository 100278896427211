import styled from "styled-components";
import colors from "../../../constants/colors";


export const ButtonStyles = styled.button`
  background: ${colors.PRIMARY_GREEN};
  color: ${colors.PRIMARY_BLACK};
  font-family: 'Inter-Bold', sans-serif;
  font-size: 18px;
  font-weight: 600;
  border: none;
  border-radius: 50px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  width: 178px;
  min-height: 49px;
  
  & .nobackground {
    width:  fit-content;
    height: fit-content;
    padding: 0px 10px;
    background-color: transparent;
    border: none;
    
  }

  &:active {
    transform: scale(0.9);
  }

  &.disabled {
    background: ${colors.PRIMARY_GRAY};
    color: ${colors.WHITE};
    cursor: not-allowed;
  }

  &.loading {
    cursor: progress;
    background: ${colors.PRIMARY_GRAY};
    color: ${colors.WHITE};
  }

  &.disabled:active {
    transform: none;
  }
`;