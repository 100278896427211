import { faAdd, faSpinner, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import Button from "../../components/Buttons/Button";
import Input from "../../components/Inputs/Input/Input";
import Modal from "../../components/Modal/Modal";
import colors from "../../constants/colors";
import { OpenModal } from "../../functions/modalFunctions";
import axiosInstance from "../../services/axiosInstance";
import { ContainerNav, CustomButton, CustomTable, FieldDivision, ModalFormContainer, Title } from "./styles";
import { Package } from "../../types/Package";
import { faEdit } from "@fortawesome/free-regular-svg-icons";

function Packages() {
	const [packagesArray, setPackages] = useState<Package[] | null>(null);
	const [selectedPackage, setSelectedPackage] = useState<Package | null>(null);
	const [loading, setLoading] = useState<boolean>(false);

	useEffect(() => {
		getData();
	}, []);

	function getData(link?: string) {
		if (link) {
			return axiosInstance.get(link).then(res => {
				setPackages(res.data.data);
			});
		}
		axiosInstance.get("/api/admin/shop/gg-coins").then(res => {
			setPackages(res.data);
		});
	}

	function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
		e.preventDefault();
		setLoading(true);
		const fd = new FormData(e.currentTarget);
		axiosInstance.put(`/api/admin/shop/gg-coins/${selectedPackage?.id}`, {
			quantity: fd.get("quantity"),
			price: fd.get("price")
		}).finally(() => {
			getData();
			setLoading(false);
		});
	}

	function handleCreate(e: React.FormEvent<HTMLFormElement>) {
		e.preventDefault();
		setLoading(true);
		const fd = new FormData(e.currentTarget);
		axiosInstance.post("/api/admin/shop/gg-coins", {
			quantity: fd.get("quantity"),
			price: fd.get("price")
		}).finally(() => {
			getData();
			setLoading(false);
		});
	}

	function handleDelete() {
		setLoading(true);
		axiosInstance.post(`/api/admin/shop/gg-coins/${selectedPackage?.id}`, {}).finally(() => { setSelectedPackage(null); getData(); setLoading(false); });
	}

	function getPackageData(id: number) {
		axiosInstance.get(`/api/admin/shop/gg-coins/${id}`).then(res => setSelectedPackage(res.data));
	}

	function editData(id: number) {
		setSelectedPackage(null);
		getPackageData(id);
		OpenModal("packageData");
	}

	return <>
		<Modal id="addPackage">
			<ModalFormContainer onSubmit={e => handleCreate(e)}>
				<FieldDivision>
					<Input disabled value="Automático" name="id">ID</Input>
					<Input name="price">Preço</Input>
				</FieldDivision>
				<FieldDivision>
					<Input name="quantity">Quantidade</Input>
				</FieldDivision>
				<Button loading={loading} disabled={loading}>CRIAR</Button>
			</ModalFormContainer>
		</Modal>
		<Modal id="packageData">
			{selectedPackage ? <ModalFormContainer onSubmit={e => handleSubmit(e)}>
				<Title>ID: {selectedPackage.id}</Title>
				<FieldDivision>
					<Input defaultValue={selectedPackage.price.toString()} name="price">Preço</Input>
					<Input defaultValue={selectedPackage.quantity.toString()} name="quantity">Quantidade</Input>
				</FieldDivision>
				<Button disabled={loading} loading={loading}>SALVAR</Button>
			</ModalFormContainer> :
				<div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}><FontAwesomeIcon icon={faSpinner} className="fa-spin" size="3x" color={colors.PRIMARY_GREEN} /></div>}
		</Modal>
		<Modal id="irreversible">
			<div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
				<Title>Operação irreversível</Title>
				<span>Deseja continuar?</span>
				<Button loading={loading} disabled={loading} onClick={() => handleDelete()}>EXCLUIR</Button>
			</div>
		</Modal>
		<ContainerNav>
			<div style={{ display: "flex", gap: 10, justifyContent: "center", alignItems: "center", height: 80 }}>
				<span style={{ fontSize: 40, fontWeight: "bold" }}>Moedas</span>
				<CustomButton onClick={() => OpenModal("addPackage")} style={{ backgroundColor: colors.PRIMARY_GREEN, border: 0, color: colors.PRIMARY_BLACK, width: 30, height: 30, display: "flex", justifyContent: "center", alignItems: "center" }}><FontAwesomeIcon icon={faAdd} /></CustomButton>
			</div>
			<div style={{ width: "100%", height: "100%", overflow: "scroll", display: "flex", justifyContent: "start", alignItems: "center", flexDirection: "column" }}>
				{packagesArray ? <CustomTable>
					<thead>
						<tr>
							<th>
								ID
							</th>
							<th>
								Quantidade
							</th>
							<th>
								Valor
							</th>
						</tr>
					</thead>
					<tbody>
						{packagesArray && packagesArray.map((data) => {
							return <tr key={data.id}>
								<td scope="row">
									{data.id}
								</td>
								<td>
									{data.quantity}
								</td>
								<td>
									{data.price}
								</td>
								<td style={{ width: 0, padding: 10 }}>
									<CustomButton onClick={() => editData(data.id)} style={{ backgroundColor: colors.PRIMARY_GREEN, border: 0, color: colors.PRIMARY_BLACK }}>
										<FontAwesomeIcon icon={faEdit} size="lg" />
									</CustomButton>
								</td>
								<td style={{ width: 0, padding: 10 }}>
									<CustomButton onClick={() => { setSelectedPackage(data); OpenModal("irreversible"); }} style={{ backgroundColor: colors.PRIMARY_RED, border: 0, color: colors.WHITE, width: 32, height: 26 }}>
										<FontAwesomeIcon icon={faTimes} size="lg" />
									</CustomButton>
								</td>
							</tr>;
						})}
					</tbody>
				</CustomTable> : <FontAwesomeIcon icon={faSpinner} className="fa-spin" size="3x" color={colors.PRIMARY_GREEN} />}
			</div>
		</ContainerNav>
	</>;
}

export default Packages;