import { createContext, useEffect, useState } from "react";
import axiosInstance from "../services/axiosInstance";
import { User } from "../types/User";

type UserContextProps = {
	user: User | undefined;
	setUser: React.Dispatch<React.SetStateAction<User | undefined>>;
}

const UserContext = createContext<UserContextProps | undefined>(undefined);

const invalidTokenMessages = ["token is invalid", "token is expired", "token not found"];

const UserContextProvider = ({ children }: { children: JSX.Element }) => {
	const [user, setUser] = useState<undefined | User>();

	useEffect(() => {
		if (localStorage.getItem("gghub@user")) {
			axiosInstance.get("/api/auth/user").then((res) => {
				if(res.data.status && invalidTokenMessages.includes(res.data.status.toLowerCase())){
					localStorage.removeItem("gghub@user");
					setUser(undefined);
				}
				else{
					if(res.data.user.permissions.includes("api.permissions.super")) {
						setUser(res.data);
					}
				}
			});
		}
	}, []);

	return <UserContext.Provider value={{ user, setUser }}>{children}</UserContext.Provider>;
};

export { UserContextProvider };
export default UserContext;