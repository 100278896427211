import styled from "styled-components";
import colors from "../../constants/colors";

export const ContainerNav = styled.div`
    display: flex;
    width: 100%;
    justify-content: flex-start;
    align-items: center;
    height: 100%;
    padding: 10px;
    flex-direction: column;
    background-color: ${colors.PRIMARY_BLACK};
    color: ${colors.WHITE};
`;

export const CustomTable = styled.table`
    margin-top: 20px;
    border-collapse: collapse;
    overflow: auto;
    width: 100%;
`;

export const CustomButton = styled.button`
    background-color: ${colors.PRIMARY_GREEN}; 
    border: 0px; 
    border-radius: 5px;
    color: ${colors.PRIMARY_BLACK};
    &:active {
        transform: scale(.9);
        transition: .2s;
    }
`;

export const ModalFormContainer = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    gap: 10px;
`;

export const Title = styled.span`
    color: ${colors.WHITE};
    font-size: 25px;
`;

export const FieldDivision = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    gap: 10px;
    min-height: 60px;
`;

/* Style the tab */
export const Tab = styled.div`
    overflow: hidden;
    align-self: flex-start;
    border: 1px solid ${colors.PRIMARY_GREEN};
    border-radius: 5px;
    background-color: transparent;
    display: flex;
    width: 100%;

    /* Style the buttons inside the tab */
    & button {
        background-color: inherit;
        float: left;
        border: none;
        outline: none;
        cursor: pointer;
        padding: 14px 16px;
        transition: 0.3s;
        font-size: 17px;
        color: ${colors.PRIMARY_GREEN};
        font-weight: bold;
        flex: 1;
    }

    /* Change background color of buttons on hover */
    & button:hover {
        background-color: ${colors.SECONDARY_GREEN};
        color: #000;
    }

    /* Create an active/current tablink class */
    & button.active {
        background-color: ${colors.PRIMARY_GREEN};
        color: #000;
    }
`;

/* Style the tab content */
export const TabContent = styled.div` 
    display: none;
    width: 100%;
    padding: 6px 12px;
    border: 1px solid ${colors.PRIMARY_GREEN};
    border-radius: 5px;

    & span {
        color: ${colors.PRIMARY_GREEN};
    }
`;

export const Jogador = styled.div`
    display: flex;
    flex-direction: column;
`;

export const Partida = styled.div`
    display: flex;
    flex-direction: column;
`;

export const BlackScreen = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 4;
    display: none;
    justify-content: center;
    align-items: center;
    padding: 20px;

    & img {
        transition: .2s ease-in-out all; 
    }
`;

export const Divisor = styled.div`
    width: 100%;
    height: 1px;
    margin: 10px 0px;
    background-color: ${colors.PRIMARY_GREEN};

`;





