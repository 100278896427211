import { faEdit } from "@fortawesome/free-regular-svg-icons";
import { faAdd, faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import { useEffect, useState } from "react";
import { Pagination, PaginationItem, PaginationLink } from "reactstrap";
import Button from "../../components/Buttons/Button";
import Input from "../../components/Inputs/Input/Input";
import Modal from "../../components/Modal/Modal";
import colors from "../../constants/colors";
import { OpenModal } from "../../functions/modalFunctions";
import axiosInstance from "../../services/axiosInstance";
import { ChallengesType } from "../../types/ChallengesType";
import { Links } from "../../types/Links";
import { PaginationData } from "../../types/PaginationData";
import { ContainerNav, CustomButton, CustomTable, FieldDivision, ModalFormContainer, Title } from "./styles";

function Challenges() {
	const [challengesArray, setChallenges] = useState<ChallengesType[] | null>(null);
	const [paginationData, setPaginationData] = useState<PaginationData | null>(null);
	const [links, setLinks] = useState<Links[] | null>(null);
	const [selectedChallenge, setSelectedChallenge] = useState<ChallengesType | null>(null);
	const [title, setTitle] = useState<string>("");

	useEffect(() => {
		getData();
	}, []);

	function getData(link?: string) {
		if (link) {
			return axios.get(link).then(res => {
				setPaginationData(res.data);
				setChallenges(res.data.data);
				setLinks(res.data.links);
			});
		}
		axiosInstance.get("/api/matches/solo").then(res => {
			setPaginationData(res.data);
			setChallenges(res.data.data);
			setLinks(res.data.links);
		});
	}

	function changeData(link: string) {
		setChallenges(null);
		getData(link);
	}

	function editData(id: number) {
		setSelectedChallenge(null);
		getChallengeData(id);
		OpenModal("challengeData");
	}

	function getChallengeData(id: number) {
		axiosInstance.get(`/api/matches/solo/${id}`).then(res => setSelectedChallenge(res.data));
	}

	function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
		e.preventDefault();
	}

	return <>
		<Modal id="challengeData">
			{selectedChallenge ? <ModalFormContainer onSubmit={e => handleSubmit(e)}>
				<Title>{selectedChallenge.title}</Title>
				<FieldDivision>
					<Input disabled value={selectedChallenge.id} name="id">ID</Input>
					<Input defaultValue={selectedChallenge.game} name="game">Jogo</Input>
				</FieldDivision>
				<FieldDivision>
					<Input defaultValue={selectedChallenge.title} name="title">Título</Input>
					<Input value={selectedChallenge.playerA ? selectedChallenge.playerA.nickname?.toString() : ""} disabled name="playerA">Usuário</Input>
				</FieldDivision>
				<FieldDivision>
					<Input value={selectedChallenge.playerB ? selectedChallenge.playerB.nickname?.toString() : ""} disabled name="playerB">Adversário</Input>
					<Input defaultValue={selectedChallenge.status} name="status">Status</Input>
				</FieldDivision>
				<FieldDivision>
					<Input value={selectedChallenge.ticket} name="ticket">Ingresso</Input>
					<Input defaultValue={selectedChallenge.prize} name="prize">Prêmio</Input>
				</FieldDivision>
				<Button>SALVAR</Button>
			</ModalFormContainer> :
				<div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}><FontAwesomeIcon icon={faSpinner} className="fa-spin" size="3x" color={colors.PRIMARY_GREEN} /></div>}
		</Modal>
		<Modal id="addChallenge">
			<ModalFormContainer onSubmit={e => handleSubmit(e)}>
				<Title>{title}</Title>
				<FieldDivision>
					<Input disabled defaultValue="Automático" name="id">ID</Input>
					<Input name="game">Jogo</Input>
				</FieldDivision>
				<FieldDivision>
					<Input name="title" value={title} onChange={e => setTitle(e.target.value)}>Título</Input>
					<Input name="playerA">Usuário ID</Input>
				</FieldDivision>
				<FieldDivision>
					<Input name="playerB">Adversário ID</Input>
					<Input defaultValue="searching" name="status">Status</Input>
				</FieldDivision>
				<FieldDivision>
					<Input name="ticket">Ingresso</Input>
					<Input name="prize">Prêmio</Input>
				</FieldDivision>
				<Button>CRIAR</Button>
			</ModalFormContainer>
		</Modal>
		<ContainerNav>
			<div style={{ display: "flex", gap: 10, justifyContent: "center", alignItems: "center", height: 80 }}>
				<span style={{ fontSize: 40, fontWeight: "bold" }}>Desafios</span>
				<CustomButton onClick={() => OpenModal("addChallenge")} style={{ backgroundColor: colors.PRIMARY_GREEN, border: 0, color: colors.PRIMARY_BLACK, width: 30, height: 30, display: "flex", justifyContent: "center", alignItems: "center" }}><FontAwesomeIcon icon={faAdd} /></CustomButton>
			</div>
			<div style={{ width: "100%", height: "100%", overflow: "scroll", display: "flex", justifyContent: "start", alignItems: "center", flexDirection: "column" }}>
				{challengesArray ? <CustomTable>
					<thead>
						<tr>
							<th>
								ID
							</th>
							<th>
								Jogo
							</th>
							<th>
								Título
							</th>
							<th>
								Usuário
							</th>
							<th>
								Adversário
							</th>
							<th>
								Status
							</th>
							<th>
								Ingresso
							</th>
							<th>
								Prêmio
							</th>
						</tr>
					</thead>
					<tbody>
						{challengesArray && challengesArray.map((challenge) => {
							return <tr key={challenge.id}>
								<td scope="row">
									{challenge.id}
								</td>
								<td>
									{challenge.game.toLocaleUpperCase()}
								</td>
								<td>
									{challenge.title}
								</td>
								<td>
									{challenge.playerA ? challenge.playerA.nickname : "--"}
								</td>
								<td>
									{challenge.playerB ? challenge.playerB.nickname : "--"}
								</td>
								<td>
									{challenge.status}
								</td>
								<td>
									{challenge.ticket}
								</td>
								<td>
									{challenge.prize}
								</td>
								<td style={{ width: 0, padding: 10 }}>
									<CustomButton onClick={() => editData(challenge.id)} style={{ backgroundColor: colors.PRIMARY_GREEN, border: 0, color: colors.PRIMARY_BLACK }}><FontAwesomeIcon icon={faEdit} size="lg" /></CustomButton>
								</td>
							</tr>;
						})}
					</tbody>
				</CustomTable> : <FontAwesomeIcon icon={faSpinner} className="fa-spin" size="3x" color={colors.PRIMARY_GREEN} />}

				<div style={{ marginTop: 20, display: "flex", justifyContent: "center" }}>
					<Pagination>
						<PaginationItem>
							<PaginationLink
								first
								onClick={() => { paginationData ? changeData(paginationData.first_page_url) : null; }}
							/>
						</PaginationItem>
						<PaginationItem>
							<PaginationLink
								previous
								onClick={() => { paginationData && paginationData.prev_page_url ? changeData(paginationData.prev_page_url) : null; }}
							/>
						</PaginationItem>
						<>{links?.length ? links.map(link => {
							if (link.label.includes("Previous") || link.label.includes("Next")) { return; }
							return <PaginationItem key={link.url} onClick={() => changeData(link.url)} className={link.active ? "active" : ""}>
								<PaginationLink>
									{link.label}
								</PaginationLink>
							</PaginationItem>;
						}) : <></>}</>
						<PaginationItem>
							<PaginationLink
								next
								onClick={() => { paginationData && paginationData.next_page_url ? changeData(paginationData.next_page_url) : null; }}
							/>
						</PaginationItem>
						<PaginationItem>
							<PaginationLink
								last
								onClick={() => { paginationData ? changeData(paginationData.last_page_url) : null; }}
							/>
						</PaginationItem>
					</Pagination>
				</div>
			</div>
		</ContainerNav>
	</>;
}

export default Challenges;