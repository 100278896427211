import { useContext, useEffect, useState } from "react";
import { Button, FormFeedback, FormGroup, Input, Label } from "reactstrap";
import colors from "../../constants/colors";
import axios from "axios";
import axiosInstance, { returnPath } from "../../services/axiosInstance";
import UserContext from "../../contexts/User";
import { useNavigate } from "react-router-dom";

function Login() {
	const [invalid, setInvalid] = useState<boolean>(false);
	const [loading, setLoading] = useState<boolean>(false);
	const Data = useContext(UserContext);
	const navigate = useNavigate();

	function login(e: React.FormEvent<HTMLFormElement>) {
		e.preventDefault();
		setLoading(true);
		const fd = new FormData(e.currentTarget);
		axios.post(`${returnPath()}/api/auth/login`, fd).then(res => {
			localStorage.setItem("gghub@user", res.data.access_token);
			getUser();
		}).catch(() => setInvalid(true)).finally(() => setLoading(false));
	}

	function getUser() {
		axiosInstance.get("/api/auth/user", { headers: { Authorization: `Bearer ${localStorage.getItem("gghub@user")}` } })
			.then((res) => {
				if (res.status === 200) {
					if(res.data.user.permissions.includes("api.permissions.super")) {
						Data?.setUser(res.data);
						return navigate("/");
					}
					return localStorage.removeItem("gghub@user");
				}
			}).catch(() => setInvalid(true));
	}

	useEffect(() => {
		if (Data?.user) {
			navigate("/app");
		}
	}, [Data]);

	return <div style={{ display: "flex", backgroundColor: colors.PRIMARY_BLACK, height: "100vh", justifyContent: "center", alignItems: "center" }}>
		<form onSubmit={(e) => login(e)} style={{ width: "fit-content", height: "fit-content", backgroundColor: colors.SECONDARY_BLACK, borderRadius: 10, display: "flex", alignItems: "flex-start", justifyContent: "flex-start", padding: 20, flexDirection: "column", gap: 10 }}>
			<FormGroup className="position-relative">
				<Label size="lg" style={{ color: "#FFF" }} for="examplePassword">
					Email
				</Label>
				<Input invalid={invalid} name="email" />
				<FormFeedback style={{ marginTop: 5 }} tooltip>
					Email errado!
				</FormFeedback>
			</FormGroup>
			<FormGroup className="position-relative">
				<Label size="lg" style={{ color: "#FFF" }} for="examplePassword">
					Senha
				</Label>
				<Input type="password" invalid={invalid} name="password" />
				<FormFeedback style={{ marginTop: 5 }} tooltip>
					Senha errada!
				</FormFeedback>
			</FormGroup>
			<Button disabled={loading} style={{ alignSelf: "center" }} color="success" type="submit">ENTRAR</Button>
		</form>
	</div>;
}

export default Login;