import { faAdd, faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { Pagination, PaginationItem, PaginationLink } from "reactstrap";
import Button from "../../components/Buttons/Button";
import Input from "../../components/Inputs/Input/Input";
import Modal from "../../components/Modal/Modal";
import colors from "../../constants/colors";
import { OpenModal } from "../../functions/modalFunctions";
import axiosInstance from "../../services/axiosInstance";
import { Links } from "../../types/Links";
import { PaginationData } from "../../types/PaginationData";
import { ContainerNav, CustomButton, CustomTable, FieldDivision, ModalFormContainer, Title } from "./styles";
import { FeeTransaction } from "../../types/FeeTransaction";

function FeesTransactions() {
	const [FeesTransactionsArray, setFeesTransactions] = useState<FeeTransaction[] | null>(null);
	const [paginationData, setPaginationData] = useState<PaginationData | null>(null);
	const [links, setLinks] = useState<Links[] | null>(null);
	const [loading, setLoading] = useState<boolean>(false);

	useEffect(() => {
		getData();
	}, []);

	function getData(link?: string) {
		if (link) {
			return axiosInstance.get(link).then(res => {
				setPaginationData(res.data);
				setFeesTransactions(res.data.data);
				setLinks(res.data.links);
			});
		}

		axiosInstance.get("/api/admin/admin-transactions").then(res => {
			setPaginationData(res.data);
			setFeesTransactions(res.data.data);
			setLinks(res.data.links);
		});
	}

	function changeData(link: string) {
		setFeesTransactions(null);
		getData(link);
	}

	function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
		e.preventDefault();
		setLoading(true);
		const fd = new FormData(e.currentTarget);
		axiosInstance.post("/api/admin/admin-transactions", fd).finally(() => {
			getData();
			setLoading(false);
		});
	}

	return <>
		<Modal id="addFeesTransaction">
			<ModalFormContainer onSubmit={e => handleSubmit(e)}>
				<Title>Nova Transação</Title>
				<FieldDivision>
					<Input disabled value="Automático" name="id">ID</Input>
					<Input name="details">Detalhes</Input>
				</FieldDivision>
				<FieldDivision>
					<Input name="value" type="number">Valor</Input>
				</FieldDivision>
				<Button>CRIAR</Button>
			</ModalFormContainer>
		</Modal>
		<ContainerNav>
			<div style={{ display: "flex", gap: 10, justifyContent: "center", alignItems: "center", height: 80 }}>
				<span style={{ fontSize: 40, fontWeight: "bold" }}>Taxas</span>
				<CustomButton onClick={() => OpenModal("addFeesTransaction")} style={{ backgroundColor: colors.PRIMARY_GREEN, border: 0, color: colors.PRIMARY_BLACK, width: 30, height: 30, display: "flex", justifyContent: "center", alignItems: "center" }}><FontAwesomeIcon icon={faAdd} /></CustomButton>
			</div>
			<div style={{ width: "100%", height: "100%", overflow: "scroll", display: "flex", justifyContent: "start", alignItems: "center", flexDirection: "column" }}>
				{FeesTransactionsArray ? <CustomTable>
					<thead>
						<tr>
							<th>
								ID
							</th>
							<th>
								Detalhes
							</th>
							<th>
								Valor
							</th>
						</tr>
					</thead>
					<tbody>
						{FeesTransactionsArray && FeesTransactionsArray.map((transaction) => {
							return <tr key={transaction.id}>
								<td scope="row">
									{transaction.id}
								</td>
								<td>
									{transaction.details}
								</td>
								<td>
									<span style={{color: parseFloat(transaction.value) < 0 ? colors.PRIMARY_RED : colors.PRIMARY_GREEN}}>{transaction.value}</span>
								</td>
							</tr>;
						})}
					</tbody>
				</CustomTable> : <FontAwesomeIcon icon={faSpinner} className="fa-spin" size="3x" color={colors.PRIMARY_GREEN} />}

				<div style={{ marginTop: 20, display: "flex", justifyContent: "center" }}>
					<Pagination>
						<PaginationItem>
							<PaginationLink
								first
								onClick={() => { paginationData ? changeData(paginationData.first_page_url) : null; }}
							/>
						</PaginationItem>
						<PaginationItem>
							<PaginationLink
								previous
								onClick={() => { paginationData && paginationData.prev_page_url ? changeData(paginationData.prev_page_url) : null; }}
							/>
						</PaginationItem>
						<>{links?.length ? links.map(link => {
							if (link.label.includes("Previous") || link.label.includes("Next")) { return; }
							return <PaginationItem key={link.url} onClick={() => changeData(link.url)} className={link.active ? "active" : ""}>
								<PaginationLink>
									{link.label}
								</PaginationLink>
							</PaginationItem>;
						}) : <></>}</>
						<PaginationItem>
							<PaginationLink
								next
								onClick={() => { paginationData && paginationData.next_page_url ? changeData(paginationData.next_page_url) : null; }}
							/>
						</PaginationItem>
						<PaginationItem>
							<PaginationLink
								last
								onClick={() => { paginationData ? changeData(paginationData.last_page_url) : null; }}
							/>
						</PaginationItem>
					</Pagination>
				</div>
			</div>
		</ContainerNav>
	</>;
}

export default FeesTransactions;