import styled from "styled-components";
import colors from "../../../constants/colors";

export const OutlinedContainer = styled.button`
  background: none;
  border: 2px solid ${colors.PRIMARY_GREEN};
  width: ${(props: { width?: string }) => props.width || "178px"};
  min-height: 49px;
  border-radius: 50px;
  cursor: pointer;
  padding: 10px;
  font-size: 16px;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.2s ease-in-out;

  & span {
    font-family: 'Inter-Bold', sans-serif;
    font-size: 18px;
    margin-right: 5px;
    margin-left: 5px;
    color: ${colors.PRIMARY_GREEN};
  }

  &:active {
    transform: scale(0.9);
  }

  &.danger {
    color: ${colors.PRIMARY_RED};
    border: 2px solid ${colors.PRIMARY_RED};
  }

  &.danger span {
    color: ${colors.PRIMARY_RED};
  }

  &.loading {
    background: ${colors.PRIMARY_GRAY};
    border: none;
    cursor: progress;
  }

  &.disabled {
    background: ${colors.PRIMARY_GRAY};
    color: ${colors.WHITE};
    border: none;
    cursor: progress;
  }

  &.disabled:active {
    transform: none;
  }
`;