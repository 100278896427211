import { faAdd, faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { Pagination, PaginationItem, PaginationLink } from "reactstrap";
import colors from "../../constants/colors";
import { OpenModal, CloseModal } from "../../functions/modalFunctions";
import axiosInstance from "../../services/axiosInstance";
import { Links } from "../../types/Links";
import { PaginationData } from "../../types/PaginationData";
import { ContainerNav, CustomButton, CustomTable, ModalFormContainer, Title } from "./styles";
import { Product } from "../../types/Product";
import Modal from "../../components/Modal/Modal";
import Input from "../../components/Inputs/Input/Input";

function OrdersPage() {
	const emptyProduct = {
		"id" : undefined,
		"title": undefined,
		"description": undefined,
		"icon": undefined,
		"created_at": undefined,
		"updated_at": undefined
	};
	
	const [products, setProducts] = useState<Product[] | null>(null);
	const [selectedProduct, setSelectedProduct] = useState<Product>(emptyProduct);
	const [paginationData, setPaginationData] = useState<PaginationData | null>(null);
	const [links, setLinks] = useState<Links[] | null>(null);

	useEffect(() => {
		getData();
	}, []);

	function getData(link?: string) {
		setProducts(null);

		if (link) {
			return axiosInstance.get(link).then(res => {
				setPaginationData(res.data);
				setProducts(res.data.data);
				setLinks(res.data.links);
			});
		}
		axiosInstance.get("/api/shop/items").then(res => {
			setPaginationData(res.data);
			setProducts(res.data.data);
			setLinks(res.data.links);
		});
	}


	function changeData(link: string) {
		setProducts(null);
		getData(link);
	}

	function handleSubmit() {
		const form = document.getElementById("productForm") as HTMLFormElement | undefined;

		const data = new FormData(form);

		axiosInstance.post("/api/admin/shop/items", data)
			.then(() => {
				CloseModal("productData");
				getData();
			});
	}

	return <>
		<ContainerNav>
			<div style={{ display: "flex", gap: 10, justifyContent: "center", alignItems: "center", height: 80 }}>
				<span style={{ fontSize: 40, fontWeight: "bold" }}>Produtos da Loja GGHub</span>
				<CustomButton onClick={() => OpenModal("productData")} style={{ backgroundColor: colors.PRIMARY_GREEN, border: 0, color: colors.PRIMARY_BLACK, width: 30, height: 30, display: "flex", justifyContent: "center", alignItems: "center" }}><FontAwesomeIcon icon={faAdd} /></CustomButton>
			</div>
			<div style={{ width: "100%", height: "100%", overflow: "scroll", display: "flex", justifyContent: "start", alignItems: "center", flexDirection: "column" }}>
				{products ? <CustomTable>
					<thead>
						<tr>
							<th>
								ID
							</th>
							<th>
								Status
							</th>
							<th>
								Usuário
							</th>
							<th>
								Item
							</th>
							<th>
								Criado
							</th>
							<th>
								Atualizado
							</th>
							<th></th>
						</tr>
					</thead>
					<tbody>
						{products && products.map((order) => {
							return <tr key={order.id}>
								<td scope="row">
									{order.id}
								</td>
								<td>
									{order.title}
								</td>
								<td>
									{order.description}
								</td>
								<td>
									<img src={order.icon} width="200"/>
								</td>
								<td>
									{order.created_at}
								</td>

								<td>
									{order.updated_at}
								</td>
								<td>
									<CustomButton>
										Editar
									</CustomButton>
								</td>
							</tr>;
						})}
					</tbody>
				</CustomTable> : <FontAwesomeIcon icon={faSpinner} className="fa-spin" size="3x" color={colors.PRIMARY_GREEN} />}

				<div style={{ marginTop: 20, display: "flex", justifyContent: "center" }}>
					<Pagination>
						<PaginationItem>
							<PaginationLink
								first
								onClick={() => { paginationData ? changeData(paginationData.first_page_url) : null; }}
							/>
						</PaginationItem>
						<PaginationItem>
							<PaginationLink
								previous
								onClick={() => { paginationData && paginationData.prev_page_url ? changeData(paginationData.prev_page_url) : null; }}
							/>
						</PaginationItem>
						<>{links?.length ? links.map(link => {
							if (link.label.includes("Previous") || link.label.includes("Next")) { return; }
							return <PaginationItem key={link.url} onClick={() => changeData(link.url)} className={link.active ? "active" : ""}>
								<PaginationLink>
									{link.label}
								</PaginationLink>
							</PaginationItem>;
						}) : <></>}</>
						<PaginationItem>
							<PaginationLink
								next
								onClick={() => { paginationData && paginationData.next_page_url ? changeData(paginationData.next_page_url) : null; }}
							/>
						</PaginationItem>
						<PaginationItem>
							<PaginationLink
								last
								onClick={() => { paginationData ? changeData(paginationData.last_page_url) : null; }}
							/>
						</PaginationItem>
					</Pagination>
				</div>
			</div>
		</ContainerNav>

		<Modal id="productData">
			{selectedProduct ? <ModalFormContainer id="productForm" onSubmit={e => { e.preventDefault(); handleSubmit(); }}>
				<Title>Novo Produto</Title>
				<Input name="title">Título</Input>
				<Input name="description">Descrição</Input>
				<Input id="iconInput" name="icon" type="file" accept="image/png,image/webp,image/jpg" >&nbsp;</Input>
				<CustomButton>SALVAR</CustomButton>
			</ModalFormContainer> :
				<div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}><FontAwesomeIcon icon={faSpinner} className="fa-spin" size="3x" color={colors.PRIMARY_GREEN} /></div>}
		</Modal>
	</>;
}

export default OrdersPage;