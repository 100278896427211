import styled from "styled-components";
import colors from "../../constants/colors";

export const ModalBackground = styled.div`
    position: absolute;
    height: 100vh;
    max-width: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    &:before {
        content: "";
        background-color: rgba(0, 0, 0, 0.4);
        z-index: 2;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
`;

export const MenuContainer = styled.div`
    display: none;
`;

export const ModalContent = styled.div`
    width: 600px;
    overflow: auto;
    max-height: 100%;
    padding: 18px;
    max-width: 90%;
    left: 50%;
    top: 20%;
    transform: translate(-50%, -20%);
    border-radius: 10px;
    background-color: ${colors.PRIMARY_BLACK};
    position: fixed;
    z-index: 4;
    border: 1px solid ${colors.PRIMARY_GREEN};
`;  