import axios from "axios";
import { CloseModal, OpenModal } from "../../../functions/modalFunctions";
import axiosInstance from "../../../services/axiosInstance";
import { Links } from "../../../types/Links";
import { PaginationData } from "../../../types/PaginationData";
import { ReportedGamesType } from "../../../types/ReportedGamesType";
import { ReportedGameType } from "../../../types/ReportedGameType";

export function getChallengeData(id: number, setSelectedChallenge: React.Dispatch<React.SetStateAction<ReportedGameType | null>>) {
	axiosInstance.get(`/api/admin/support/match/${id}`).then(res => setSelectedChallenge(res.data));
}

export function translateStatus(status?: string) {
	switch (status) {
	case "searching":
		return "Procurando";
	case "ingame":
		return "Em jogo";
	case "validating":
		return "Votando";
	case "ended":
		return "Finalizado";
	case "reported":
		return "Reportado";
	default:
		return "Erro";
	}
}

export function editData(id: number, setSelectedChallenge: React.Dispatch<React.SetStateAction<ReportedGameType | null>>) {
	setSelectedChallenge(null);
	getChallengeData(id, setSelectedChallenge);
	OpenModal("challengeData");
	openTab(null, "Jogadores");
}

export function statusDropdownToggle(setStatusDropdown: React.Dispatch<React.SetStateAction<boolean>>) {
	setStatusDropdown((prevState) => !prevState);
}

export function votePlayerADropdownToggle(setVotePlayerADropdown: React.Dispatch<React.SetStateAction<boolean>>) {
	setVotePlayerADropdown((prevState) => !prevState);
}

export function votePlayerBDropdownToggle(setVotePlayerBDropdown: React.Dispatch<React.SetStateAction<boolean>>) {
	setVotePlayerBDropdown((prevState) => !prevState);
}

export function openBlackScreen(url: string) {
	const blackScreen = document.getElementById("blackScreen");
	if (blackScreen) {
		blackScreen.style.display = "flex";
	}
	const image = document.getElementById("blackScreenImage") as HTMLImageElement;
	if (image) {
		image.src = url;
		image.style.width = "auto";
		image.style.height = "auto";
		image.style.maxWidth = "100%";
		image.style.maxHeight = "100%";
		image.style.zIndex = "100";
		image.style.display = "block";
	}
}

export function closeBlackScreen() {
	const blackScreen = document.getElementById("blackScreen");
	if (blackScreen) {
		blackScreen.style.display = "none";
	}
}

export function openTab(evt: any, tabName: string) {
	let i;
	const oparationButtons = document.getElementById("oparationButtons");
	if (tabName === "Dados") {
		if (oparationButtons) {
			oparationButtons.style.display = "flex";
		}
	} else {
		if (oparationButtons) {
			oparationButtons.style.display = "none";
		}
	}
	const tabcontent = Array.from(document.getElementsByClassName("tabcontent") as HTMLCollectionOf<HTMLElement>);
	for (i = 0; i < tabcontent.length; i++) {
		tabcontent[i].style.display = "none";
	}
	const tablinks = document.getElementsByClassName("tablinks");
	for (i = 0; i < tablinks.length; i++) {
		tablinks[i].className = tablinks[i].className.replace(" active", "");
	}
	const tab = document.getElementById(tabName);
	if (tab) {
		tab.style.display = "block";
	}
	if (evt) {
		evt.currentTarget.className += " active";
	} else {
		const tablinks = document.getElementsByClassName("tablinks");
		if (tablinks[0]) {
			tablinks[0].className += " active";
		}
	}
}

export function getData(setPaginationData: React.Dispatch<React.SetStateAction<PaginationData | null>>, setChallenges: React.Dispatch<React.SetStateAction<ReportedGamesType[] | null>>, setLinks: React.Dispatch<React.SetStateAction<Links[] | null>>, link?: string) {
	if (link) {
		return axios.get(link).then(res => {
			setPaginationData(res.data);
			setChallenges(res.data.data);
			setLinks(res.data.links);
		});
	}
	axiosInstance.get("/api/admin/support/match/pending").then(res => {
		setPaginationData(res.data);
		setChallenges(res.data.data);
		setLinks(res.data.links);
	});
}

export function changeData(setChallenges: React.Dispatch<React.SetStateAction<ReportedGamesType[] | null>>, setPaginationData: React.Dispatch<React.SetStateAction<PaginationData | null>>, setLinks: React.Dispatch<React.SetStateAction<Links[] | null>>, link: string) {
	setChallenges(null);
	getData(setPaginationData, setChallenges, setLinks, link);
}

export function handleChangeStatus(e: React.FormEvent<HTMLFormElement>, selectedMatch: ReportedGameType, status: string, setSelectedChallenge: React.Dispatch<React.SetStateAction<ReportedGameType | null>>, setPaginationData: React.Dispatch<React.SetStateAction<PaginationData | null>>, setChallenges: React.Dispatch<React.SetStateAction<ReportedGamesType[] | null>>, setLinks: React.Dispatch<React.SetStateAction<Links[] | null>>) {
	e.preventDefault();
	let newStatus = "";
	switch (status) {
	case "Procurando":
		newStatus = "searching";
		break;
	case "Em jogo":
		newStatus = "ingame";
		break;
	case "Votando":
		newStatus = "validating";
		break;
	case "Reportado":
		newStatus = "reported";
		break;
	case "Finalizado":
		newStatus = "ended";
		break;
	default:
		break;
	}

	const statusData = {
		status: newStatus,
	};

	if(newStatus !== selectedMatch.match_status) {
		axiosInstance.post(`/api/admin/support/match/${selectedMatch.match_id}/change-status`, statusData).then((res) => {
			getChallengeData(selectedMatch.match_id, setSelectedChallenge);
			if(res.status === 200) {
				alert("Dados alterados com sucesso!");
				getData(setPaginationData, setChallenges, setLinks);
				CloseModal("challengeData");
			}
		});
	}
}

export function handleChangeVotes(e: React.FormEvent<HTMLFormElement>, selectedMatch: ReportedGameType, votePlayerA: string, votePlayerB: string, setSelectedChallenge: React.Dispatch<React.SetStateAction<ReportedGameType | null>> ) {
	e.preventDefault();
	const newVotePlayerA = votePlayerA === "Ganhou" ? 1 : 0;
	const newVotePlayerB = votePlayerB === "Ganhou" ? 1 : 0;

	if(newVotePlayerA !== selectedMatch.result_playerA || newVotePlayerB !== selectedMatch.result_playerB) {
		axiosInstance.post(`/api/admin/support/match/${selectedMatch.match_id}/change-votes`, {
			votePlayerA: newVotePlayerA,
			votePlayerB: newVotePlayerB,
		}).then((res) => {
			getChallengeData(selectedMatch.match_id, setSelectedChallenge);
			if(res.status === 200) {
				alert("Dados alterados com sucesso!");
			}
		});
	}

	
}