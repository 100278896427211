import { faWarning } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import OutlinedButton from "../../../../components/Buttons/OutlinedButton";
import colors from "../../../../constants/colors";
import { CloseModal } from "../../../../functions/modalFunctions";
import axiosInstance from "../../../../services/axiosInstance";
import { Links } from "../../../../types/Links";
import { PaginationData } from "../../../../types/PaginationData";
import { ReportedGamesType } from "../../../../types/ReportedGamesType";
import { ReportedGameType } from "../../../../types/ReportedGameType";
import { getChallengeData, getData } from "../../Functions/Actions";
import { Container } from "./styles";

export default function RefundModal(props: { match: ReportedGameType | null, setSelectedChallenge: React.Dispatch<React.SetStateAction<ReportedGameType | null>>, getChallengeData: (id: number, setSelectedChallenge: React.Dispatch<React.SetStateAction<ReportedGameType | null>>) => void, setPaginationData: React.Dispatch<React.SetStateAction<PaginationData | null>>, setChallenges: React.Dispatch<React.SetStateAction<ReportedGamesType[] | null>>, setLinks: React.Dispatch<React.SetStateAction<Links[] | null>> }) {
	function handleRepay() {
		axiosInstance.post(`/api/admin/support/match/${props.match?.match_id}/cancel`).then(res => {
			if(res.status === 200) {
				alert("Dados alterados com sucesso!");
				getData(props.setPaginationData, props.setChallenges, props.setLinks);
				CloseModal("challengeData");
				CloseModal("reportModalWarning");
			}
		}).catch(err => {
			const feedback = document.getElementById("feedbackRefund");
			if (feedback) feedback.innerText = "Ocorreu um erro ao encerrar o reporte. Tente novamente. CODE: " + err.response.status;
		}).finally(() => {
			getChallengeData(props.match?.match_id || 0, props.setSelectedChallenge);
		});
	}

	return (
		<Container>
			<FontAwesomeIcon icon={faWarning} size="5x" color={colors.PRIMARY_RED} />
			<span style={{ color: colors.WHITE, fontSize: 20, textAlign: "center" }}>As operações devem ser feitas com cautela. Prossiga com cuidado.</span>
			<div style={{ display: "flex", width: "100%", alignItems: "center", flexDirection: "column", gap: 5, marginTop: 20 }}>
				<OutlinedButton onClick={() => { handleRepay(); }} danger width="130px">Reembolsar</OutlinedButton>
				<span id="feedbackRefund" style={{ marginTop: 5, color: colors.PRIMARY_RED, fontSize: 18, textAlign: "center" }} />
				<span role="button" style={{ color: colors.PRIMARY_GREEN, textDecoration: "underline" }} onClick={() => { CloseModal("reportModalWarning"); }}>Voltar</span>
			</div>
		</Container>
	);
}