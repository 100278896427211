import { useContext, useEffect, useState } from "react";
import UserContext from "../contexts/User";
import Challenges from "../Pages/Challenges";
import Template from "../Template";
import { useNavigate } from "react-router-dom";

function App() {
	const [page, setPage] = useState<JSX.Element>(<Challenges />);
	const Data = useContext(UserContext);
	const navigate = useNavigate();

	useEffect(() => {
		if (!Data?.user) {
			navigate("/login");
		}
	}, [Data]);

	return (
		<Template setPage={setPage}>
			{page}
		</Template>
	);

}

export default App;
