import { useEffect, useRef } from "react";
import * as React from "react";
import { StyledTextarea, TextareaDiv } from "./styled";

type TextareaProps = {
	name: string;
	children: JSX.Element | JSX.Element[];
	disabled?: boolean;
	defaultValue?: string;
	id?: string;
	mask?: string;
	placeholder?: string;
	type?: string;
	value?: string;
	onChange?: ((e: React.ChangeEvent<HTMLTextAreaElement> | undefined) => void);
}



const Textarea = (props: TextareaProps) => {
	const input = useRef<HTMLTextAreaElement>(null);

	const hasInput = () => {
		if (input.current) {
			if (input.current.value === "" || props.value === "") {
				input.current.classList.add("no-input");
			}
			else {
				input.current.classList.remove("no-input");
			}
		}
	};

	useEffect(() => {
		hasInput();
	}, [input.current?.value, props.value]);

	return <TextareaDiv>
		<StyledTextarea defaultValue={props.defaultValue} name={props.name} ref={input} id={props.id ? props.id : props.name} onInput={hasInput} onChange={(e) => {
			if (props.onChange) {
				props.onChange(e);
			}
		}} disabled={props.disabled} placeholder={props.placeholder} className={`${props.disabled ? "disabled" : ""}`} />
		<label htmlFor={props.name}>{props.children}</label>
	</TextareaDiv>
	;
};

export default Textarea;