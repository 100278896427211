import { faAdd, faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Pagination, PaginationItem, PaginationLink } from "reactstrap";
import colors from "../../constants/colors";
import { OpenModal } from "../../functions/modalFunctions";
import axiosInstance from "../../services/axiosInstance";
import { Links } from "../../types/Links";
import { PaginationData } from "../../types/PaginationData";
import { ContainerNav, CustomButton, CustomTable } from "./styles";
import { Orders } from "../../types/Order";

function OrdersPage() {
	const [orders, setOrders] = useState<Orders[] | null>(null);
	const [paginationData, setPaginationData] = useState<PaginationData | null>(null);
	const [status, setStatus] = useState<string>("Mudar Status");
	const [links, setLinks] = useState<Links[] | null>(null);
	const [dropdownOpen, setDropdownOpen] = useState<boolean>(false);

	useEffect(() => {
		setStatus("Mudar Status");
		getData();
	}, []);

	function changeOrderStatus(status: string, id: number) {
		axiosInstance.post(`/api/admin/shop/orders/${id}/set-status`, {status}).then(() => {
			getData();
		});
	}

	function toggle() {
		setDropdownOpen((prevState) => !prevState);
	}

	function getData(link?: string) {
		setOrders(null);
		if (link) {
			return axiosInstance.get(link).then(res => {
				setPaginationData(res.data);
				setOrders(res.data.data);
				setLinks(res.data.links);
			});
		}
		axiosInstance.get("/api/admin/shop/orders").then(res => {
			setPaginationData(res.data);
			setOrders(res.data.data);
			setLinks(res.data.links);
		});
	}

	function changeData(link: string) {
		setOrders(null);
		getData(link);
	}

	return <>
		<ContainerNav>
			<div style={{ display: "flex", gap: 10, justifyContent: "center", alignItems: "center", height: 80 }}>
				<span style={{ fontSize: 40, fontWeight: "bold" }}>Resgates</span>
				<CustomButton onClick={() => OpenModal("addTransaction")} style={{ backgroundColor: colors.PRIMARY_GREEN, border: 0, color: colors.PRIMARY_BLACK, width: 30, height: 30, display: "flex", justifyContent: "center", alignItems: "center" }}><FontAwesomeIcon icon={faAdd} /></CustomButton>
			</div>
			<div style={{ width: "100%", height: "100%", overflow: "scroll", display: "flex", justifyContent: "start", alignItems: "center", flexDirection: "column" }}>
				{orders ? <CustomTable>
					<thead>
						<tr>
							<th>
								ID
							</th>
							<th>
								Status
							</th>
							<th>
								Usuário
							</th>
							<th>
								Item
							</th>
							<th>
								Criado
							</th>
							<th>
								Atualizado
							</th>
							<th></th>
						</tr>
					</thead>
					<tbody>
						{orders && orders.map((order) => {
							return <tr key={order.id}>
								<td scope="row">
									{order.id}
								</td>
								<td>
									{order.status}
								</td>
								<td>
									{order.user_id}
								</td>
								<td>
									{order.item_id}
								</td>
								<td>
									{order.created_at}
								</td>

								<td>
									{order.updated_at}
								</td>
								<td>
									<Dropdown style={{ width: "100%", maxWidth: "251px", height: 60 }} isOpen={dropdownOpen} toggle={toggle} direction="down">
										<DropdownToggle style={{ width: "100%", maxWidth: "251px", border: `1px solid ${colors.PRIMARY_GREEN}`, height: 60, backgroundColor: colors.PRIMARY_BLACK, color: colors.PRIMARY_GREEN }} size="lg" caret>{status}</DropdownToggle>
										<DropdownMenu style={{ width: 251 }} dark>
											<DropdownItem onClick={() => { changeOrderStatus("pending", order.id); }}>Pendente</DropdownItem>
											<DropdownItem onClick={() => { changeOrderStatus("delivered", order.id); }}>Entregue</DropdownItem>
										</DropdownMenu>
									</Dropdown>
								</td>
							</tr>;
						})}
					</tbody>
				</CustomTable> : <FontAwesomeIcon icon={faSpinner} className="fa-spin" size="3x" color={colors.PRIMARY_GREEN} />}

				<div style={{ marginTop: 20, display: "flex", justifyContent: "center" }}>
					<Pagination>
						<PaginationItem>
							<PaginationLink
								first
								onClick={() => { paginationData ? changeData(paginationData.first_page_url) : null; }}
							/>
						</PaginationItem>
						<PaginationItem>
							<PaginationLink
								previous
								onClick={() => { paginationData && paginationData.prev_page_url ? changeData(paginationData.prev_page_url) : null; }}
							/>
						</PaginationItem>
						<>{links?.length ? links.map(link => {
							if (link.label.includes("Previous") || link.label.includes("Next")) { return; }
							return <PaginationItem key={link.url} onClick={() => changeData(link.url)} className={link.active ? "active" : ""}>
								<PaginationLink>
									{link.label}
								</PaginationLink>
							</PaginationItem>;
						}) : <></>}</>
						<PaginationItem>
							<PaginationLink
								next
								onClick={() => { paginationData && paginationData.next_page_url ? changeData(paginationData.next_page_url) : null; }}
							/>
						</PaginationItem>
						<PaginationItem>
							<PaginationLink
								last
								onClick={() => { paginationData ? changeData(paginationData.last_page_url) : null; }}
							/>
						</PaginationItem>
					</Pagination>
				</div>
			</div>
		</ContainerNav>
	</>;
}

export default OrdersPage;