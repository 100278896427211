import { CloseModal } from "../../functions/modalFunctions";
import { MenuContainer, ModalBackground, ModalContent } from "./styles";

const Modal = (props: { id: string, children: JSX.Element[] | JSX.Element, style?: React.CSSProperties, notFirst?: boolean }) => {
	return (
		<MenuContainer id={props.id}>
			{props.notFirst ?
				<></> :
				<ModalBackground onClick={() => {
					CloseModal(props.id);
				}} />
			}
			<ModalContent style={props.style}>
				{props.children}
			</ModalContent>
		</MenuContainer>
	);
};

export default Modal;