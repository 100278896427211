import { ReportedGameType } from "../../../types/ReportedGameType";

function WhoReported(match: ReportedGameType, playerId: number | undefined): JSX.Element | null {
	if (match.reported_by === playerId) {
		return <span>- Reportou</span>;
	} else {
		return null;
	}
}

export default WhoReported;