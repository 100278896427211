import { faAdd, faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import { useEffect, useState } from "react";
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Pagination, PaginationItem, PaginationLink } from "reactstrap";
import Button from "../../components/Buttons/Button";
import Input from "../../components/Inputs/Input/Input";
import Modal from "../../components/Modal/Modal";
import colors from "../../constants/colors";
import { OpenModal } from "../../functions/modalFunctions";
import axiosInstance from "../../services/axiosInstance";
import { LolTournamentType } from "../../types/LolTournamentType";
import { Links } from "../../types/Links";
import { PaginationData } from "../../types/PaginationData";
import { ContainerNav, CustomButton, CustomTable, FieldDivision, ModalFormContainer, Title } from "./styles";
import Textarea from "../../components/Inputs/Textarea/textarea";

function LolTournaments() {
	const [LolTournamentsArray, setLolTournaments] = useState<LolTournamentType[] | null>(null);
	const [paginationData, setPaginationData] = useState<PaginationData | null>(null);
	const [links, setLinks] = useState<Links[] | null>(null);
	const [title, setTitle] = useState<string>("");
	const [type, setType] = useState<"Normal" | "Ranked" | "Aram" | "Tft">("Ranked");
	const [loading, setLoading] = useState<boolean>(false);
	const [dropdownOpen, setDropdownOpen] = useState<boolean>(false);

	useEffect(() => {
		getData();
	}, []);

	function getData(link?: string) {
		if (link) {
			return axios.get(link).then(res => {
				setPaginationData(res.data);
				setLolTournaments(res.data.data);
				setLinks(res.data.links);
			});
		}
		axiosInstance.get("/api/tournament").then(res => {
			setPaginationData(res.data);
			setLolTournaments(res.data.data);
			setLinks(res.data.links);
		});
	}

	function changeData(link: string) {
		setLolTournaments(null);
		getData(link);
	}


	function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
		e.preventDefault();
		setLoading(true);
		const input1place = document.getElementsByName("first_place")[0] as HTMLInputElement;
		const input2place = document.getElementsByName("second_place")[0] as HTMLInputElement;
		const input3place = document.getElementsByName("third_place")[0] as HTMLInputElement;
		const start_at = document.getElementsByName("start_at")[0] as HTMLInputElement;
		const end_at = document.getElementsByName("end_at")[0] as HTMLInputElement;

		const formatedStatedAt = new Date(start_at.value).toISOString();
		const formatedEndAt = new Date(end_at.value).toISOString();

		const fd = new FormData(e.currentTarget);
		fd.append("prize_distribution", JSON.stringify([input1place.value, input2place.value, input3place.value]));
		fd.set("start_at", formatedStatedAt);
		fd.set("end_at", formatedEndAt);

		axiosInstance.post("/api/tournament", fd).finally(() => {
			setLoading(false);
		});
	}

	function toggle() {
		setDropdownOpen((prevState) => !prevState);
	}

	return <>
		<Modal id="addLolTournament">
			<ModalFormContainer onSubmit={e => handleSubmit(e)}>
				<Title>{title}</Title>
				<FieldDivision>
					<Input disabled defaultValue="Automático" name="id">ID</Input>
					<Input name="title" value={title} onChange={e => setTitle(e.target.value)}>Título</Input>
				</FieldDivision>
				<FieldDivision>
					<Input name="slots">Capacidade</Input>
					<Input name="match_count">Num. Partidas</Input>
				</FieldDivision>
				<FieldDivision>
					<Input type="datetime-local" name="start_at">Começo</Input>
					<Input type="datetime-local" name="end_at">Final</Input>
				</FieldDivision>
				<FieldDivision>
					<Input name="ticket" style={{ width: "100%", maxWidth: 251 }}>Ingresso</Input>
					<Dropdown style={{ width: "100%", maxWidth: "251px", height: 60 }} isOpen={dropdownOpen} toggle={toggle} direction="down">
						<DropdownToggle style={{ width: "100%", maxWidth: "251px", border: `1px solid ${colors.PRIMARY_GREEN}`, height: 60, backgroundColor: colors.PRIMARY_BLACK, color: colors.PRIMARY_GREEN }} size="lg" caret>{type}</DropdownToggle>
						<DropdownMenu style={{ width: 251 }} dark>
							<DropdownItem onClick={() => { setType("Normal"); }}>Normal</DropdownItem>
							<DropdownItem onClick={() => { setType("Ranked"); }}>Ranked</DropdownItem>
							<DropdownItem onClick={() => { setType("Aram"); }}>Aram</DropdownItem>
							<DropdownItem onClick={() => { setType("Tft"); }}>Tft</DropdownItem>
						</DropdownMenu>
					</Dropdown>
				</FieldDivision>
				<FieldDivision>
					<Input type="number" name="first_place">1º lugar (%)</Input>
					<Input type="number" name="second_place">2º lugar (%)</Input>
					<Input type="number" name="third_place">3º lugar (%)</Input>
				</FieldDivision>
				<Textarea name="rules">
					<span>Regras</span>
				</Textarea>
				<Button loading={loading} disabled={loading}>CRIAR</Button>
			</ModalFormContainer>
		</Modal>
		<ContainerNav>
			<div style={{ display: "flex", gap: 10, justifyContent: "center", alignItems: "center", height: 80 }}>
				<span style={{ fontSize: 40, fontWeight: "bold" }}>Torneios de LOL</span>
				<CustomButton onClick={() => OpenModal("addLolTournament")} style={{ backgroundColor: colors.PRIMARY_GREEN, border: 0, color: colors.PRIMARY_BLACK, width: 30, height: 30, display: "flex", justifyContent: "center", alignItems: "center" }}><FontAwesomeIcon icon={faAdd} /></CustomButton>
			</div>
			<div style={{ width: "100%", height: "100%", overflow: "scroll", display: "flex", justifyContent: "start", alignItems: "center", flexDirection: "column" }}>
				{LolTournamentsArray ? <CustomTable>
					<thead>
						<tr>
							<th>
								ID
							</th>
							<th>
								Título
							</th>
							<th>
								Capacidade
							</th>
							<th>
								Jogadores
							</th>
							<th>
								Status
							</th>
							<th>
								Entrada
							</th>
							<th>
								Prêmio
							</th>
						</tr>
					</thead>
					<tbody>
						{LolTournamentsArray && LolTournamentsArray.map((LolTournament) => {
							return <tr key={LolTournament.id}>
								<td scope="row">
									{LolTournament.id}
								</td>
								<td>
									{LolTournament.title}
								</td>
								<td>
									{LolTournament.slots}
								</td>
								<td>
									{LolTournament.players_count}
								</td>
								<td>
									{LolTournament.status_text}
								</td>
								<td>
									{LolTournament.ticket}
								</td>
								<td>
									{LolTournament.prize}
								</td>
							</tr>;
						})}
					</tbody>
				</CustomTable> : <FontAwesomeIcon icon={faSpinner} className="fa-spin" size="3x" color={colors.PRIMARY_GREEN} />}

				<div style={{ marginTop: 20, display: "flex", justifyContent: "center" }}>
					<Pagination>
						<PaginationItem>
							<PaginationLink
								first
								onClick={() => { paginationData ? changeData(paginationData.first_page_url) : null; }}
							/>
						</PaginationItem>
						<PaginationItem>
							<PaginationLink
								previous
								onClick={() => { paginationData && paginationData.prev_page_url ? changeData(paginationData.prev_page_url) : null; }}
							/>
						</PaginationItem>
						<>{links?.length ? links.map(link => {
							if (link.label.includes("Previous") || link.label.includes("Next")) { return; }
							return <PaginationItem key={link.url} onClick={() => changeData(link.url)} className={link.active ? "active" : ""}>
								<PaginationLink>
									{link.label}
								</PaginationLink>
							</PaginationItem>;
						}) : <></>}</>
						<PaginationItem>
							<PaginationLink
								next
								onClick={() => { paginationData && paginationData.next_page_url ? changeData(paginationData.next_page_url) : null; }}
							/>
						</PaginationItem>
						<PaginationItem>
							<PaginationLink
								last
								onClick={() => { paginationData ? changeData(paginationData.last_page_url) : null; }}
							/>
						</PaginationItem>
					</Pagination>
				</div>
			</div>
		</ContainerNav>
	</>;
}

export default LolTournaments;