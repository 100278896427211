import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { Pagination, PaginationItem, PaginationLink } from "reactstrap";
import colors from "../../constants/colors";
import axiosInstance from "../../services/axiosInstance";
import { Links } from "../../types/Links";
import { PaginationData } from "../../types/PaginationData";
import { ContainerNav, CustomTable } from "./styles";
import { Bill } from "../../types/Bill";

function Bills() {
	const [billsArray, setBills] = useState<Bill[] | null>(null);
	const [paginationData, setPaginationData] = useState<PaginationData | null>(null);
	const [links, setLinks] = useState<Links[] | null>(null);

	useEffect(() => {
		getData();
	}, []);

	function getData(link?: string) {
		if (link) {
			return axiosInstance.get(link).then(res => {
				setPaginationData(res.data);
				setBills(res.data.data);
				setLinks(res.data.links);
			});
		}
		axiosInstance.get("/api/admin/bills").then(res => {
			setPaginationData(res.data);
			setBills(res.data.data);
			setLinks(res.data.links);
		});
	}

	function changeData(link: string) {
		setBills(null);
		getData(link);
	}
  
	return <>
		<ContainerNav>
			<div style={{ display: "flex", gap: 10, justifyContent: "center", alignItems: "center", height: 80 }}>
				<span style={{ fontSize: 40, fontWeight: "bold" }}>Faturas</span>
			</div>
			<div style={{ width: "100%", height: "100%", overflow: "scroll", display: "flex", justifyContent: "start", alignItems: "center", flexDirection: "column" }}>
				{billsArray ? <CustomTable>
					<thead>
						<tr>
							<th>
								ID
							</th>
							<th>
								Usuário
							</th>
							<th>
								Status
							</th>
							<th>
								Valor
							</th>
							<th>
								Produto
							</th>
							<th>
								Quantidade
							</th>
						</tr>
					</thead>
					<tbody>
						{billsArray && billsArray.map((bill) => {
							return <tr key={bill.id}>
								<td scope="row">
									{bill.id}
								</td>
								<td>
									{bill.user}
								</td>
								<td style={{color: bill.status === "approved" ? colors.PRIMARY_GREEN : "#FFF", textTransform: "capitalize"}}>
									{bill.status}
								</td>
								<td>
									{bill.price}
								</td>
								<td>
									{bill.product}
								</td>
								<td>
									{bill.quantity}
								</td>
							</tr>;
						})}
					</tbody>
				</CustomTable> : <FontAwesomeIcon icon={faSpinner} className="fa-spin" size="3x" color={colors.PRIMARY_GREEN} />}

				<div style={{ marginTop: 20, display: "flex", justifyContent: "center" }}>
					<Pagination>
						<PaginationItem>
							<PaginationLink
								first
								onClick={() => { paginationData ? changeData(paginationData.first_page_url) : null; }}
							/>
						</PaginationItem>
						<PaginationItem>
							<PaginationLink
								previous
								onClick={() => { paginationData && paginationData.prev_page_url ? changeData(paginationData.prev_page_url) : null; }}
							/>
						</PaginationItem>
						<>{links?.length ? links.map(link => {
							if (link.label.includes("Previous") || link.label.includes("Next")) { return; }
							return <PaginationItem key={link.url} onClick={() => changeData(link.url)} className={link.active ? "active" : ""}>
								<PaginationLink>
									{link.label}
								</PaginationLink>
							</PaginationItem>;
						}) : <></>}</>
						<PaginationItem>
							<PaginationLink
								next
								onClick={() => { paginationData && paginationData.next_page_url ? changeData(paginationData.next_page_url) : null; }}
							/>
						</PaginationItem>
						<PaginationItem>
							<PaginationLink
								last
								onClick={() => { paginationData ? changeData(paginationData.last_page_url) : null; }}
							/>
						</PaginationItem>
					</Pagination>
				</div>
			</div>
		</ContainerNav>
	</>;
}

export default Bills;