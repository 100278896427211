import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App/App";
import Login from "./Pages/Login";
import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { UserContextProvider } from "./contexts/User";
import GlobalStyle from "./styles";

const root = ReactDOM.createRoot(
	document.getElementById("root") as HTMLElement
);

root.render(
	<React.StrictMode>
		<UserContextProvider>
			<BrowserRouter>
				<GlobalStyle/>
				<Routes>
					<Route index path="/" element={<Login />} />
					<Route path="/app" element={<App />} />
					<Route path="*" element={<Login />} />
				</Routes>
			</BrowserRouter>
		</UserContextProvider>
	</React.StrictMode>
);
