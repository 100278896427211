import { faAdd, faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Pagination, PaginationItem, PaginationLink } from "reactstrap";
import Button from "../../components/Buttons/Button";
import Input from "../../components/Inputs/Input/Input";
import Modal from "../../components/Modal/Modal";
import colors from "../../constants/colors";
import { OpenModal } from "../../functions/modalFunctions";
import axiosInstance from "../../services/axiosInstance";
import { User } from "../../types/User";
import { Links } from "../../types/Links";
import { PaginationData } from "../../types/PaginationData";
import { ContainerNav, CustomButton, CustomTable, FieldDivision, ModalFormContainer, Title } from "./styles";
import CheckBoxButton from "../../components/Buttons/CheckBoxButton";

function Users() {
	const [usersArray, setUsers] = useState<User["user"][] | null>(null);
	const [paginationData, setPaginationData] = useState<PaginationData | null>(null);
	const [links, setLinks] = useState<Links[] | null>(null);
	const [dropdownOpen, setDropdownOpen] = useState<boolean>(false);
	const [nickname, setNickname] = useState<string>("");
	const [status, setStatus] = useState<string>("Status");

	useEffect(() => {
		getData();
	}, []);

	function getData(link?: string) {
		if (link) {
			return axiosInstance.get(link).then(res => {
				setPaginationData(res.data);
				setUsers(res.data.data);
				setLinks(res.data.links);
			});
		}
		axiosInstance.get("/api/admin/users").then(res => {
			setPaginationData(res.data);
			setUsers(res.data.data);
			setLinks(res.data.links);
		});
	}

	function changeData(link: string) {
		setUsers(null);
		getData(link);
	}

	function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
		e.preventDefault();
	}

	function toggle() {
		setDropdownOpen((prevState) => !prevState);
	}

	return <>
		<Modal id="addUser">
			<ModalFormContainer onSubmit={e => handleSubmit(e)}>
				<Title>{nickname}</Title>
				<FieldDivision>
					<Input disabled value="Automático" name="id">ID</Input>
					<Input value={nickname} onChange={e => setNickname(e.target.value)} name="game">Nickname</Input>
				</FieldDivision>
				<FieldDivision>
					<Input name="email">Email</Input>
					<Dropdown style={{ width: "100%", maxWidth: "251px", height: 60 }} isOpen={dropdownOpen} toggle={toggle} direction="down">
						<DropdownToggle style={{ width: "100%", maxWidth: "251px", border: `1px solid ${colors.PRIMARY_GREEN}`, height: 60, backgroundColor: colors.PRIMARY_BLACK, color: colors.PRIMARY_GREEN }} size="lg" caret>{status}</DropdownToggle>
						<DropdownMenu style={{ width: 251 }} dark>
							<DropdownItem onClick={() => { setStatus("Ativo"); }}>Ativo</DropdownItem>
							<DropdownItem onClick={() => { setStatus("Pendente"); }}>Pendente</DropdownItem>
							<DropdownItem onClick={() => { setStatus("Banido"); }}>Banido</DropdownItem>
							<DropdownItem onClick={() => { setStatus("Suspenso"); }}>Suspenso</DropdownItem>
						</DropdownMenu>
					</Dropdown>
				</FieldDivision>
				<FieldDivision>
					<Input type="date" style={{ width: 251 }} name="birthdate">Data de Nascimento</Input>
					<Input defaultValue="user" name="status">Privilégio</Input>
				</FieldDivision>
				<FieldDivision>
					<Input type="password" name="password">Senha</Input>
				</FieldDivision>
				<FieldDivision>
					<CheckBoxButton id="permission">Admin</CheckBoxButton>
				</FieldDivision>
				<Button>CRIAR</Button>
			</ModalFormContainer>
		</Modal>
		<ContainerNav>
			<div style={{ display: "flex", gap: 10, justifyContent: "center", alignItems: "center", height: 80 }}>
				<span style={{ fontSize: 40, fontWeight: "bold" }}>Usuários</span>
				<CustomButton onClick={() => OpenModal("addUser")} style={{ backgroundColor: colors.PRIMARY_GREEN, border: 0, color: colors.PRIMARY_BLACK, width: 30, height: 30, display: "flex", justifyContent: "center", alignItems: "center" }}><FontAwesomeIcon icon={faAdd} /></CustomButton>
			</div>
			<div style={{ width: "100%", height: "100%", overflow: "scroll", display: "flex", justifyContent: "start", alignItems: "center", flexDirection: "column" }}>
				{usersArray ? <CustomTable>
					<thead>
						<tr>
							<th>
								ID
							</th>
							<th>
								Nome de usuário
							</th>
							<th>
								Email
							</th>
							<th>
								Verificado
							</th>
							<th>
								Status
							</th>
							<th>
								Privilégio
							</th>
							<th>
								Data de nascimento
							</th>
						</tr>
					</thead>
					<tbody>
						{usersArray && usersArray.map((userData) => {
							const user = userData;
							return <tr key={user.id}>
								<td scope="row">
									{user.id}
								</td>
								<td>
									{user.nickname}
								</td>
								<td>
									{user.email}
								</td>
								<td>
									{user.email_verified_at ? "verificado" : "--"}
								</td>
								<td>
									{user.status}
								</td>
								<td>
									{user.privilege}
								</td>
								<td>
									{user.birthdate}
								</td>
							</tr>;
						})}
					</tbody>
				</CustomTable> : <FontAwesomeIcon icon={faSpinner} className="fa-spin" size="3x" color={colors.PRIMARY_GREEN} />}

				<div style={{ marginTop: 20, display: "flex", justifyContent: "center" }}>
					<Pagination>
						<PaginationItem>
							<PaginationLink
								first
								onClick={() => { paginationData ? changeData(paginationData.first_page_url) : null; }}
							/>
						</PaginationItem>
						<PaginationItem>
							<PaginationLink
								previous
								onClick={() => { paginationData && paginationData.prev_page_url ? changeData(paginationData.prev_page_url) : null; }}
							/>
						</PaginationItem>
						<>{links?.length ? links.map(link => {
							if (link.label.includes("Previous") || link.label.includes("Next")) { return; }
							return <PaginationItem key={link.url} onClick={() => changeData(link.url)} className={link.active ? "active" : ""}>
								<PaginationLink>
									{link.label}
								</PaginationLink>
							</PaginationItem>;
						}) : <></>}</>
						<PaginationItem>
							<PaginationLink
								next
								onClick={() => { paginationData && paginationData.next_page_url ? changeData(paginationData.next_page_url) : null; }}
							/>
						</PaginationItem>
						<PaginationItem>
							<PaginationLink
								last
								onClick={() => { paginationData ? changeData(paginationData.last_page_url) : null; }}
							/>
						</PaginationItem>
					</Pagination>
				</div>
			</div>
		</ContainerNav>
	</>;
}

export default Users;