import { faAdd, faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Pagination, PaginationItem, PaginationLink } from "reactstrap";
import Button from "../../components/Buttons/Button";
import Input from "../../components/Inputs/Input/Input";
import Modal from "../../components/Modal/Modal";
import colors from "../../constants/colors";
import { CloseModal, OpenModal } from "../../functions/modalFunctions";
import axiosInstance from "../../services/axiosInstance";
import { Links } from "../../types/Links";
import { PaginationData } from "../../types/PaginationData";
import { ContainerNav, CustomButton, CustomTable, FieldDivision, ModalFormContainer, Title } from "./styles";
import CheckBoxButton from "../../components/Buttons/CheckBoxButton";
import { WithdrawType } from "../../types/WithdrawType";
import { faEdit } from "@fortawesome/free-regular-svg-icons";

function Withdraw() {
	const [WithdrawArray, setWithdraw] = useState<WithdrawType[] | null>(null);
	const [paginationData, setPaginationData] = useState<PaginationData | null>(null);
	const [links, setLinks] = useState<Links[] | null>(null);
	const [dropdownOpen1, setDropdownOpen1] = useState<boolean>(false);
	const [dropdownOpen2, setDropdownOpen2] = useState<boolean>(false);
	const [nickname, setNickname] = useState<string>("");
	const [status, setStatus] = useState<string>("Status");
	const [responseStatus, setResponseStatus] = useState("Pendente");
	const [selectedWithdraw, setSelectedWithdraw] = useState<WithdrawType | null>(null);
	const [loading, setLoading] = useState<boolean>(false);

	useEffect(() => {
		getData();
	}, []);

	function getData(link?: string) {
		if (link) {
			return axiosInstance.get(link).then(res => {
				setPaginationData(res.data);
				setWithdraw(res.data.data);
				setLinks(res.data.links);
			});
		}
		axiosInstance.get("/api/admin/withdrawal").then(res => {
			setPaginationData(res.data);
			setWithdraw(res.data.data);
			setLinks(res.data.links);
		});
	}

	function changeData(link: string) {
		setWithdraw(null);
		getData(link);
	}

	function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
		e.preventDefault();
		setLoading(true);
		const fd = new FormData(e.currentTarget);
		let definitiveResponseStatus = "";
		switch(responseStatus){
		case "Pendente":
			definitiveResponseStatus = "pending";
			break;
		case "Rejeitado": 
			definitiveResponseStatus = "rejected";
			break;
		case "Entregue": 
			definitiveResponseStatus = "delivered";
			break;
		default:
			definitiveResponseStatus = "pending";
		}
		fd.append("status", definitiveResponseStatus);
		fd.append("id", selectedWithdraw ? selectedWithdraw.id.toString() : "");
		axiosInstance.post("/api/admin/withdrawal/change-status", fd).then(res => {
			if(res.status === 200) {
				getData();
				alert("Dados modificados.");
				CloseModal("withdrawData");
			}
		}).finally(() => {
			setLoading(false);
		});
	}

	function toggle1() {
		setDropdownOpen1((prevState) => !prevState);
	}

	function toggle2() {
		setDropdownOpen2((prevState) => !prevState);
	}

	function editData(withdraw: WithdrawType) {
		setSelectedWithdraw(withdraw);
		OpenModal("withdrawData");
	}

	return <>
		<Modal id="addWithdraw">
			<ModalFormContainer onSubmit={e => handleSubmit(e)}>
				<Title>{nickname}</Title>
				<FieldDivision>
					<Input disabled value="Automático" name="id">ID</Input>
					<Input value={nickname} onChange={e => setNickname(e.target.value)} name="game">Nickname</Input>
				</FieldDivision>
				<FieldDivision>
					<Input name="title">Email</Input>
					<Dropdown id="addWithdrawForm" style={{ width: "100%", maxWidth: "251px", height: 60 }} isOpen={dropdownOpen1} toggle={toggle1} direction="down">
						<DropdownToggle style={{ width: "100%", maxWidth: "251px", border: `1px solid ${colors.PRIMARY_GREEN}`, height: 60, backgroundColor: colors.PRIMARY_BLACK, color: colors.PRIMARY_GREEN }} size="lg" caret>{status}</DropdownToggle>
						<DropdownMenu style={{ width: 251 }} dark>
							<DropdownItem onClick={() => { setStatus("Ativo"); }}>Ativo</DropdownItem>
							<DropdownItem onClick={() => { setStatus("Pendente"); }}>Pendente</DropdownItem>
							<DropdownItem onClick={() => { setStatus("Banido"); }}>Banido</DropdownItem>
							<DropdownItem onClick={() => { setStatus("Suspenso"); }}>Suspenso</DropdownItem>
						</DropdownMenu>
					</Dropdown>
				</FieldDivision>
				<FieldDivision>
					<Input type="date" style={{ width: 251 }} name="birthdate">Data de Nascimento</Input>
					<Input defaultValue="user" name="status">Privilégio</Input>
				</FieldDivision>
				<FieldDivision>
					<CheckBoxButton id="permission">Admin</CheckBoxButton>
				</FieldDivision>
				<Button>CRIAR</Button>
			</ModalFormContainer>
		</Modal>
		<Modal id="withdrawData">
			{selectedWithdraw ? <ModalFormContainer onSubmit={e => handleSubmit(e)}>
				<Title>{selectedWithdraw.user.nickname}</Title>
				<FieldDivision>
					<Input name="deny_reason">Resposta</Input>
					<Dropdown id="addResponseStatus" style={{ width: "100%", maxWidth: "251px", height: 60 }} isOpen={dropdownOpen2} toggle={toggle2} direction="down">
						<DropdownToggle style={{ width: "100%", maxWidth: "251px", border: `1px solid ${colors.PRIMARY_GREEN}`, height: 60, backgroundColor: colors.PRIMARY_BLACK, color: colors.PRIMARY_GREEN }} size="lg" caret>{responseStatus}</DropdownToggle>
						<DropdownMenu container="body" style={{ width: 251 }} dark>
							<DropdownItem onClick={() => { setResponseStatus("Pendente"); }}>Pendente</DropdownItem>
							<DropdownItem onClick={() => { setResponseStatus("Entregue"); }}>Entregue</DropdownItem>
							<DropdownItem onClick={() => { setResponseStatus("Rejeitado"); }}>Rejeitado</DropdownItem>
						</DropdownMenu>
					</Dropdown>
				</FieldDivision>
				<Button disabled={loading} loading={loading}>SALVAR</Button>
			</ModalFormContainer> :
				<div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}><FontAwesomeIcon icon={faSpinner} className="fa-spin" size="3x" color={colors.PRIMARY_GREEN} /></div>}
		</Modal>
		<ContainerNav>
			<div style={{ display: "flex", gap: 10, justifyContent: "center", alignItems: "center", height: 80 }}>
				<span style={{ fontSize: 40, fontWeight: "bold" }}>Saques</span>
				<CustomButton onClick={() => OpenModal("addWithdraw")} style={{ backgroundColor: colors.PRIMARY_GREEN, border: 0, color: colors.PRIMARY_BLACK, width: 30, height: 30, display: "flex", justifyContent: "center", alignItems: "center" }}><FontAwesomeIcon icon={faAdd} /></CustomButton>
			</div>
			<div style={{ width: "100%", height: "100%", overflow: "scroll", display: "flex", justifyContent: "start", alignItems: "center", flexDirection: "column" }}>
				{WithdrawArray ? <CustomTable>
					<thead>
						<tr>
							<th>
								ID
							</th>
							<th>
								Usuário
							</th>
							<th>
								Status
							</th>
							<th>
								Quantidade
							</th>
							<th>
								Detalhes
							</th>
							<th>
								Resposta
							</th>
						</tr>
					</thead>
					<tbody>
						{WithdrawArray && WithdrawArray.map((withdraw) => {
							return <tr key={withdraw.id}>
								<td scope="row">
									{withdraw.id}
								</td>
								<td>
									{withdraw.user.nickname}
								</td>
								<td>
									{withdraw.status}
								</td>
								<td>
									{withdraw.quantity}
								</td>
								<td>
									{withdraw.details}
								</td>
								<td>
									{withdraw.deny_reason}
								</td>
								<td style={{ width: 0, padding: 10 }}>
									<CustomButton onClick={() => editData(withdraw)} style={{ backgroundColor: colors.PRIMARY_GREEN, border: 0, color: colors.PRIMARY_BLACK }}><FontAwesomeIcon icon={faEdit} size="lg" /></CustomButton>
								</td>
							</tr>;
						})}
					</tbody>
				</CustomTable> : <FontAwesomeIcon icon={faSpinner} className="fa-spin" size="3x" color={colors.PRIMARY_GREEN} />}

				<div style={{ marginTop: 20, display: "flex", justifyContent: "center" }}>
					<Pagination>
						<PaginationItem>
							<PaginationLink
								first
								onClick={() => { paginationData ? changeData(paginationData.first_page_url) : null; }}
							/>
						</PaginationItem>
						<PaginationItem>
							<PaginationLink
								previous
								onClick={() => { paginationData && paginationData.prev_page_url ? changeData(paginationData.prev_page_url) : null; }}
							/>
						</PaginationItem>
						<>{links?.length ? links.map(link => {
							if (link.label.includes("Previous") || link.label.includes("Next")) { return; }
							return <PaginationItem key={link.url} onClick={() => changeData(link.url)} className={link.active ? "active" : ""}>
								<PaginationLink>
									{link.label}
								</PaginationLink>
							</PaginationItem>;
						}) : <></>}</>
						<PaginationItem>
							<PaginationLink
								next
								onClick={() => { paginationData && paginationData.next_page_url ? changeData(paginationData.next_page_url) : null; }}
							/>
						</PaginationItem>
						<PaginationItem>
							<PaginationLink
								last
								onClick={() => { paginationData ? changeData(paginationData.last_page_url) : null; }}
							/>
						</PaginationItem>
					</Pagination>
				</div>
			</div>
		</ContainerNav>
	</>;
}

export default Withdraw;