import styled from "styled-components";
import colors from "../../../constants/colors";

export const CheckBoxStyle = styled.div`
  width: fit-content;
  max-width: 100%;

.container {
  display: block;
  position: relative;
  padding-left: 35px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  color: ${colors.WHITE};
}

.container a {
  color: ${colors.PRIMARY_GREEN};
}

.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.container.disabled {
  cursor: not-allowed;
}

.checkmark {
  position: absolute;
  top: 5px;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: transparent;
  border: 3px solid ${colors.PRIMARY_GRAY};
  border-radius: 5px;
  transition: all .2s ease-in-out;
}

.checkmark.disabled {
  border: 1px solid ${colors.PRIMARY_GRAY};	
}

.container input:checked ~ .checkmark {
  border: 6px solid ${colors.PRIMARY_GREEN};
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.container input:checked ~ .checkmark:after {
  display: block;
}
`;