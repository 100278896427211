import styled from "styled-components";
import colors from "../../../constants/colors";

export const StyledTextarea = styled.textarea`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    padding: 5px 15px 0px 9px;

    width: 100%;
    min-width: 315px;

    min-height: 110px;

    border: 1px solid ${colors.PRIMARY_GREEN};
    border-radius: 10px;

    font-size: 18px;
    color: ${colors.WHITE};

    background-color: ${colors.PRIMARY_BLACK};

    &:-webkit-autofill,
    &:-webkit-autofill:hover, 
    &:-webkit-autofill:focus, 
    &:-webkit-autofill:active{
        -webkit-box-shadow: 0 0 0 30px #1D2229 inset !important;
        box-shadow: 0 0 0 30px #1D2229 inset !important;
        -webkit-text-fill-color: white !important;
        transition: background-color 5000s ease-in-out 0s;
    }

    &::-webkit-datetime-edit { color: transparent; }

    &::-webkit-calendar-picker-indicator {
            align-self: flex-start;
            margin-top: 2px;
            background-color: ${colors.PRIMARY_GREEN}; opacity: 1;
            border-radius: 5px;
        }

    &::placeholder {
        opacity: 0;
    }

    &::-webkit-input-placeholder { /* Edge */
        opacity: 0;

    }

    &:-ms-input-placeholder { /* Internet Explorer */
        opacity: 0;
    }

    &:focus {
        border: 1px solid ${colors.PRIMARY_GREEN};
        outline: none;
        background-color: ${colors.PRIMARY_BLACK};

        &::-webkit-datetime-edit { color: #FFF; }

        

        &::placeholder {
            opacity: 1;
        }

        &::-webkit-input-placeholder { /* Edge */
            opacity: 1;

        }

        &:-ms-input-placeholder { /* Internet Explorer */
            opacity: 1;
        }
    }

    &.disabled {
        background-color: ${colors.SECONDARY_GRAY};
        cursor: not-allowed;
        border: none;
    }

    @media (max-width: 768px) {
        min-width: 100%;
        min-height: 130px;
    }
    
`;

export const TextareaDiv = styled.div`
    position: relative;
    max-width: 100%;
    width: 100%;

    & label {
        position: absolute;
        top: 5px;
        left: 10px;
        font-size: 22px;
        display: none;
        color: ${colors.PRIMARY_GREEN};
        transition: all 0.2s ease-in-out;
    }

    & textarea.no-input ~ label {
        display: block;
    }

    & textarea:focus ~ label {
        display: none;
    }
`;