import styled from "styled-components";
import colors from "../../constants/colors";

export const ContainerNav = styled.div`
    display: flex;
    width: 100%;
    justify-content: flex-start;
    align-items: center;
    height: 100%;
    padding: 10px;
    flex-direction: column;
    background-color: ${colors.PRIMARY_BLACK};
    color: ${colors.WHITE};
`;

export const CustomTable = styled.table`
    margin-top: 20px;
    border-collapse: collapse;
    overflow: auto;
    width: 100%;
`;

export const CustomButton = styled.button`
    background-color: ${colors.PRIMARY_GREEN}; 
    border: 0px; 
    border-radius: 5px;
    color: ${colors.PRIMARY_BLACK};
    &:active {
        transform: scale(.9);
        transition: .2s;
    }
`;

export const ModalFormContainer = styled.form`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    padding: 5px;
    gap: 20px;
`;

export const Title = styled.span`
    color: ${colors.WHITE};
    font-size: 25px;
`;

export const FieldDivision = styled.div`
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
`;