import { useContext, useState } from "react";
import {
	Collapse,
	Navbar,
	NavbarToggler,
	NavbarBrand,
	Nav,
	NavItem,
	NavLink,
	NavbarText
} from "reactstrap";
import colors from "../constants/colors";
import UserContext from "../contexts/User";
import Bills from "../Pages/Bills";
import Challenges from "../Pages/Challenges";
import FeesTransactions from "../Pages/FeesTransactions";
import LolTournaments from "../Pages/LolTournaments";
import Packages from "../Pages/Packages";
import ReportedGames from "../Pages/ReportedGames";
import Transactions from "../Pages/Transactions";
import Users from "../Pages/Users";
import Withdraw from "../Pages/Withdrawal";
import CoinShop from "../Pages/CoinsShop";
import CoinsShopProduct from "../Pages/CoinsShopProduct";
import "./styles.css";

function Template({ children, setPage }: { children?: JSX.Element | JSX.Element[], setPage: React.Dispatch<React.SetStateAction<JSX.Element>> }) {
	const screenWidth = screen.width;
	const [isOpen, setIsOpen] = useState(false);
	const Data = useContext(UserContext);

	const toggle = () => setIsOpen(!isOpen);

	function Loggout() {
		localStorage.removeItem("gghub@user");
		Data?.setUser(undefined);
	}

	return <div className="container-fluid-custom">
		<div>
			<Navbar dark color="dark">
				<NavbarBrand href="#" style={{ color: colors.PRIMARY_GREEN }}>GGHub</NavbarBrand>
				<NavbarToggler onClick={toggle} className="toggle-button" />
				<Collapse isOpen={screenWidth < 1024 ? isOpen : true} navbar>
					<Nav className="me-auto" navbar>
						<NavItem>
							<NavLink href="#Desafios" onClick={() => {
								setPage(<Challenges />);
							}}>Desafios</NavLink>
						</NavItem>
						<NavItem>
							<NavLink href="#Torneios-Lol" onClick={() => {
								setPage(<LolTournaments />);
							}}>Torneios - Lol</NavLink>
						</NavItem>
						<NavItem>
							<NavLink href="#Usuarios" onClick={() => {
								setPage(<Users />);
							}}>Usuários</NavLink>
						</NavItem>
						<NavItem>
							<NavLink href="#Transacoes-taxas" onClick={() => {
								setPage(<Transactions />);
							}}>Transações</NavLink>
						</NavItem>
						<NavItem>
							<NavLink href="#Faturas" onClick={() => {
								setPage(<Bills />);
							}}>Faturas</NavLink>
						</NavItem>
						<NavItem>
							<NavLink href="#Moedas" onClick={() => {
								setPage(<Packages />);
							}}>Moedas</NavLink>
						</NavItem>
						<NavItem>
							<NavLink href="#Saques" onClick={() => {
								setPage(<Withdraw />);
							}}>Saques</NavLink>
						</NavItem>
						<NavItem>
							<NavLink href="#Taxas" onClick={() => {
								setPage(<FeesTransactions />);
							}}>Taxas</NavLink>
						</NavItem>
						<NavItem>
							<NavLink href="#Jogos-reportados" onClick={() => {
								setPage(<ReportedGames />);
							}}>Reportes</NavLink>
						</NavItem>
						<NavItem>
							<NavLink href="#loja-de-pontos" onClick={() => {
								setPage(<CoinShop />);
							}}>Loja de Pontos</NavLink>
						</NavItem>
						<NavItem>
							<NavLink href="#loja-de-pontos" onClick={() => {
								setPage(<CoinsShopProduct />);
							}}>Produtos da Loja</NavLink>
						</NavItem>
					</Nav>
					<NavbarText onClick={() => { Loggout(); }} style={{ color: colors.PRIMARY_RED, cursor: "pointer" }}>Sair</NavbarText>
				</Collapse>
			</Navbar>
		</div>
		{children}
	</div>;
}

export default Template;